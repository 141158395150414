/* 
    The Authentication Feature is used in almost every feature that requires authentication

    It is taylored to interface the codingcow.de API based on Django with DRF[with social] and SimpleJWT

    Some of the backend functions are rather unique but overall this feature is quite fit for reuse. 
    It depends on some core hooks in the parent project to write to handle cookies and CRSF tokens as well as
    axios for all api requests.    
*/


// Context
import { AuthProvider } from "./context/AuthProvider";

// Hooks
import { useAuth } from "./hooks/useAuth"
import { useRefreshToken } from "./hooks/useRefreshToken";
import { useApiPrivate } from "./hooks/useApiPrivate";
import { useLogout } from "./hooks/useLogout";
// The Account Menu
import { AccountMenu } from "./components/AccountMenu/AccountMenu";

// Special Elements      
import { LoginPersistance } from "./LoginPersistance.js/LoginPersistance";
import { SocialCallback } from "./SocialLogin/Callback/Callback";
import { PrivateRoute } from "./Routing/PrivateRoute/PrivateRoute";

// Components
import { SocialLoginButton } from "./SocialLogin/SocialLoginButton/SocialLoginButton";
import { SocialLogin } from "./SocialLogin/SocialLogin/SocialLogin";
import { LoginButton } from "./components/LoginButton/LoginButton";
import { RegisterButton } from "./components/RegisterButton/RegisterButton";

// Forms
import { LoginForm } from "./forms/LoginForm/LoginForm";
import { RegisterForm } from "./forms/RegisterForm/RegisterForm";
import { PasswordResetForm } from "./forms/PasswordResetForm/PasswordResetForm";
import { AccountRecoveryPage } from "./pages/AccountRecovery";
import { LoginPage } from "./pages/Login";
import { RegisterPage } from "./pages/Register";
import { Routes, Route } from "react-router-dom";

const AuthenticationRoutes = () => {
    return (
        <Routes>
            <Route exact path="/login" element={<LoginPage />} />
            <Route exact path="/register" element={<RegisterPage />} />
            <Route exact path="/recover" element={<AccountRecoveryPage />} />
            <Route path="/social/:provider/callback/" element={<SocialCallback />} />
        </Routes>
    )
}
export default AuthenticationRoutes


const PATTERN_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})/
const PATTERN_USERNAME = /^[a-zA-Z0-9-_!?\.]{3,130}$/
const PATTERN_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@:;.,<>\-_=+\*'£$%\^&]).{7,130}$/


export {
    // Constants
    PATTERN_EMAIL,
    PATTERN_USERNAME,
    PATTERN_PASSWORD,
    // Context
    AuthProvider,
    // Hooks    
    useAuth,
    useRefreshToken,
    useApiPrivate,
    useLogout,
    // The Account Menu
    AccountMenu,
    // Components
    SocialLoginButton,
    SocialLogin,
    LoginButton,
    RegisterButton,
    // Special Elements      
    PrivateRoute,
    SocialCallback,
    LoginPersistance,
    // Forms
    LoginForm,
    RegisterForm,
    PasswordResetForm,
    // Pages 
    LoginPage,
    RegisterPage,
    AccountRecoveryPage

}