import React, { useState } from 'react';
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,

} from 'mdb-react-ui-kit';
import {
    NavLink
} from "react-router-dom";

import {AccountMenu} from 'features/Authentication'
import logo from 'assets/img/logo.webp'


export default function Header() {
    const [showBasic, setShowBasic] = useState(false);

    return (
        <>
            <div style={{ height: '0.1rem', display: 'flex', flexDirection: 'row', position: 'fixed', top: '0', left: '0', right: '0', zIndex: '1031' }}>
                <div style={{ backgroundColor: 'skyblue', width: '100%', opacity: '1.0' }}></div>
                <div style={{ backgroundColor: 'skyblue', width: '100%', opacity: '0.7' }}></div>
                <div style={{ backgroundColor: 'skyblue', width: '100%', opacity: '0.3' }}></div>
                <div style={{ backgroundColor: 'lightgreen', width: '100%', opacity: '0.3' }}></div>
                <div style={{ backgroundColor: 'lightgreen', width: '100%', opacity: '0.7' }}></div>
                <div style={{ backgroundColor: 'lightgreen', width: '100%', opacity: '1.0' }}></div>
                <div style={{ backgroundColor: 'green', width: '100%', opacity: '0.5' }}></div>
                <div style={{ backgroundColor: 'darkgreen', width: '100%', opacity: '0.6' }}></div>
            </div>
            <MDBNavbar expand='lg' dark fixed='top' className='p-0 bg-primary'>
                <MDBContainer fluid>
                    <NavLink className='navbar-brand' to="/">
                        <div style={{
                            width: '2.5rem',
                            height: '2.5rem',
                            
                            display: 'flex',
                            alignItems: 'center',
                            

                        }}>
                            <img
                                src={logo}
                                style={{ width: '100%', height: 'auto' }}
                                alt='codingcow'
                            />
                        </div> </NavLink>
                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => setShowBasic(!showBasic)}
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>
                    <MDBCollapse navbar show={showBasic}>
                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
                            <MDBNavbarItem>
                                <NavLink className='nav-link' to="/blog">Blog</NavLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <NavLink className='nav-link' to="/webhosting">Webhosting</NavLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <NavLink className='nav-link' to="/domains">Domains</NavLink>
                            </MDBNavbarItem>
                        </MDBNavbarNav>
                        <AccountMenu />
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
        </>
    )
}

