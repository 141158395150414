


import { useEffect, useState } from "react"
import  axios  from "api/axios"
import { useParams } from 'react-router-dom';


export const usePlans = ()=>{
    /* returns the available webhosting plans */
    const [plans, setPlans] = useState([])
    // planId is the url parameter to identify a plan use it when building a route that selects a plan
    const {planId} = useParams() 
    
    const [selectedPlan, setSelectedPlan] = useState({})
    const [otherPlans, setOtherPlans] = useState([])
  

    useEffect(()=>{
        const retrieve = async () =>{
            try {
                const response = await axios.get('/web/plans/')
                const _plans = response?.data?.results
                setPlans(_plans)
                    
            } catch (error) {
                console.error(`network error: ${error}`)
            }
        }
        retrieve()
    },[])


    /* Update selected plan and other plans */
    useEffect(() => {      
        setSelectedPlan(plans.filter(plan => plan.id == planId).at(-1))
        setOtherPlans(plans.filter(plan => plan.id != planId))
    }, [plans, planId])


    return ([plans, selectedPlan, otherPlans])

}