import './GalleryPreview.css'

export const GalleryPreview = ({img, onClick}) => {
    return(
        <div className="gallery-preview">            
            <h5>{img?.title}</h5>
            <img src={img?.url} alt={img?.description} onClick={()=>{onClick()}}/>
            <br />
            <p>{img?.description}</p>
        </div>
    )
}