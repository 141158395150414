
import logo from '../../../assets/img/cow-512.png'; // change to a lower resolution in product

const SpinningCowHead = () => {  
    /* Depends on CSS classes in index.css */
    return (
        <div className="spinner-container flex-wrap">
            <p className="spinner-text">Please wait ...</p>
            <img src={logo} className="spinner" alt="Loading spinner" />
            <p className="spinner-text">... is loading ...</p>
        </div>
    )
}

export default SpinningCowHead

export const LoadingOrContent = ({ loading, children }) => {
    /* Controled by the parent Element, 
    this element will display the spinning cow head  */
    return (
        loading
            ? (<SpinningCowHead />)
            : (children)
    )
}


