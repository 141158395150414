import React, { useState, useEffect } from 'react';
import axios from 'api/axios';
import { LoadingOrContent } from 'components/ui/Spinners/SpinningCowHead';
import { WHPlanCard } from '../PlanCard/PlanCard';

export const WHPlansList = () => {

    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const getPlans = async () => {
            try {
                const response = await axios.get('/web/plans')
                console.log(response?.data)
                setPlans(response?.data?.results)
            } catch (err) {
                setError('Oops. Something went wrong. Make sure you have a connection and try again.')
            } finally {
                setLoading(false)
            }
        }
        getPlans()
    }, [])

    return (
        <>
            <div className="row " id="webhostingPlansViewport">
                <LoadingOrContent loading={loading}>
                    {error ? (<p className='note note-danger'>{error}</p>) : (
                        plans.map(plan => (
                            <WHPlanCard key={plan.id} plan={plan} />
                        )))}
                </LoadingOrContent>
            </div>
        </>
    )
}