import axios from "api/axios"
import {useAuth} from "features/Authentication"
import useCookies from "hooks/useCookies"

export const useLogout = () =>{
    const { setAuth, setPersist } = useAuth()
    const {setCookie} = useCookies()

    const logout = async ()=>{
        setAuth({})
        setPersist(false)
        setCookie('refresh', undefined, 1)
        try{
            const r = await axios.post('/logout/', {
                withCredentials:true
            })
        }catch(error){
            console.error(error)
        }
    }
    return logout
}

