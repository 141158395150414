import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import { MDBContainer } from "mdb-react-ui-kit";

export const BaseLayout = () => {
    return (
        <main>
            <Header />

            <MDBContainer
                style={{ marginTop: '4.3rem',  minHeight: '80vh' }}  >

                <Outlet />

            </MDBContainer>

            <Footer />
        </main>
    )
}




