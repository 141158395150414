import { MDBBadge, MDBCard, MDBCardBody, MDBCardHeader, MDBIcon } from 'mdb-react-ui-kit';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const PLANS_URL = '/web/plans'
export const WHPlanCard = ({ plan }) => {
    const { t } = useTranslation()

    const getSoftwareDetails = () => {
        switch (plan.install_software) {
            case 'WP':
                return ['wordpress', 'Wordpress']
            case 'JO':
                return ['joomla', 'Joomla']
            case 'DR':
                return ['drupal', 'Drupal']
            default:
                return ['', '']
        }
    }
    const [softwareIcon, softwareName] = getSoftwareDetails()

    /* WebhostingPlanCard represents a card with details about a hostingplan and a link to the webhosting subscription page */
    return (
        <><div className="col-md-4 col-xl-3">
            <MDBCard className='mb-3 small hover-zoom'>
                <MDBCardHeader className='text-center  bg-primary bg-gradient opacity-95 ' >
                    <h3 className="h6 text-white text-uppercase">{plan?.name} </h3>
                </MDBCardHeader>
                <MDBCardBody className='text-center'>
                    <p className=' text-uppercase text-muted small'>
                        {t(`webhosting.plans.${plan?.plan_internal_name}.shortDescription`)}
                    </p>
                    <hr className='hr hr-blurry' />
                    <div className='col-12 d-flex justify-content-center'>
                        <ul className="list-unstyled  text-start col-sm-6 col-md-7">

                            <li><MDBIcon fas icon='hdd' /> {plan?.storage_gb} GB {t('webhosting.plans.items.storage')}</li>
                            <li><MDBIcon fas icon='envelope' /> {plan?.email_accounts} {t('webhosting.plans.items.inboxes')}</li>
                            <li><MDBIcon fas icon="database" /> {plan?.databases} {t('webhosting.plans.items.databases')}</li>
                            <>{plan?.subdomains != 0 ? (
                                <li className="plan-list-item-subdomain"><MDBIcon fas icon="globe" /> {plan?.subdomains} Subdomains</li>
                            ) : null}
                            </>
                            <>
                                {
                                    plan?.install_software && (
                                        <li><MDBIcon fab icon={softwareIcon} /> {softwareName}  {t('webhosting.plans.items.instant')} </li>
                                    )
                                }
                            </>
                        </ul>

                    </div>
                    
                    <MDBBadge> 
                       {t('webhosting.plans.starting-from')} {plan?.plan_price_monthly} {t('webhosting.currency.symbol')}
                    </MDBBadge>
                    <hr className='hr hr-blurry' />
                    <NavLink
                        to={`/webhosting/${plan.id}/`}
                        className='text-center btn btn-success opacity-70'
                    >
                        {t('webhosting.components.PlanCard.call-to-action')}
                    </NavLink>
                </MDBCardBody>
            </MDBCard>
        </div>
        </>
    )
}
