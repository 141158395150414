import { MDBListGroupItem } from "mdb-react-ui-kit"
import { NavLink } from "react-router-dom"

export const BlogCategoriesListItem = ({ catId, name, articles }) => {
    return (
        <MDBListGroupItem className='p-0 m-0' >
            <NavLink
                to={`/blog/categories/${catId}/`}               
                className='list-group-item border-0 pb-0 d-flex justify-content-between'
            >
                <p>{name}</p>
                <p>{articles?.length || '0'}</p>
            </NavLink>
        </MDBListGroupItem>
    )
}