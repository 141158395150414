import './GalleryLightBox.css'
export const GalleryLightBox = ({img, active, onClick}) => {
    return (
        <>
        {active && 
            <div className="gallery-lightbox">
                <img src={img.url} alt={img.description} onClick={()=>{onClick()}} /> 
            </div>}
        </>
    )
}