/* The Register Page */
import { RegisterForm, SocialLogin } from 'features/Authentication'
import { NavLink } from 'react-router-dom'
import { MDBContainer } from 'mdb-react-ui-kit'
import { useTranslation } from 'react-i18next'

export const RegisterPage = () => {
  const { t } = useTranslation()
  return (
      <section className='overflow-hidden mb-5'>
          <MDBContainer className='slide-in gap-1 col-12 col-xl-6 border border-0 rounded-5 d-flex flex-column align-items-center'>
              <RegisterForm />
              <SocialLogin title={t('authentication.headings.social-register')} />              
              <div className='d-flex flex-row justify-content-center mb-3'>
                  <NavLink className='btn btn-link btn-sm' to='/recover/'>
                      {t('authentication.nav.account-recovery')}
                  </NavLink>
                  <NavLink className='btn btn-link btn-sm' to='/login/'>
                      {t('authentication.nav.login')}
                  </NavLink>
              </div>
          </MDBContainer>
      </section>
  )
}
