import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

console.log('Loading language')
console.log(navigator.language)


i18n
//  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: navigator.language.slice(0,2), // should be supported by all modern browsers 
    resources: {
      en: {
        translations: {
          // general stuff like slogans and fixed content, policies and button texts
          general: require('i18n/locales/en/translation.json'),

          // more specific components 
          //  components: require('components/locales/en/translation.json'),

          // sorted by and loaded from features          
          authentication: require('features/Authentication/locales/en/translation.json'),
          //  profile: require('features/Profile/locales/en/translation.json'),
          //  blog: require('features/Blog/locales/en/translation.json'),
          //  shop: require('features/Shop/locales/en/translation.json'),
              webhosting: require('features/Webhosting/locales/en/translation.json'),
          //  webhostingPanel: require('features/WebhostingPanel/locales/en/translation.json')       
          // }
        }
      },
      de: {
        translations: {
          // general stuff like slogans and fixed content, policies and button texts
          general: require('./locales/de/translation.json'),

          // more specific components 
          components: require('components/locales/de/translation.json'),

          // sorted by and loaded from features    
          authentication: require('features/Authentication/locales/de/translation.json'),
          profile: require('features/Profile/locales/de/translation.json'),
          blog: require('features/Blog/locales/de/translation.json'),
          shop: require('features/Shop/locales/de/translation.json'),
          webhosting: require('features/Webhosting/locales/de/translation.json'),
          webhostingPanel: require('features/WebhostingPanel/locales/de/translation.json')
        }
      }
    },
    ns: ['translations'],
    defaultNS: 'translations'
  });

i18n.languages = ['en', 'de'];

export default i18n;