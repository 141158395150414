import './SlimGallery.css'
import { useCallback, useState } from "react"

import { GalleryListItem } from "features/SlimGallery"
import { GalleryPreview } from "features/SlimGallery"
import { GalleryLightBox } from "features/SlimGallery"

export const SlimGallery = ({ images }) => {
    const [selectedImg, setSelectedImg] = useState(0)
    const [lightBoxActive, setLightBoxActive] = useState(false)

    const select = useCallback((index) => {
        setSelectedImg(index)
    }, [])

    const toggleLightBox = () => {
        setLightBoxActive(!lightBoxActive)
    }

    return (
        <div className="slim-gallery">
            {
                images.length && 
                    <div onClick={toggleLightBox}>
                        <GalleryPreview img={images[selectedImg]}  />
                        <GalleryLightBox img={images[selectedImg]} active={lightBoxActive} />
                    </div>
            }
            <div className="item-list">
                { images.length &&
                     images.map((img, i) => (<GalleryListItem key={i} index={i} img={img} select={select} />     )                                   
                )}
            </div>                    
        </div>
    )
}