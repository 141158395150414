import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import './PlanAddons.css'
import axios from 'api/axios'

export const WHPlanAddons = ({ onChange }) => {
    // Render a table that shows the current plan addons and allows to adjust them
    const { t } = useTranslation()
    const listItemClassName = "d-flex flex-row justify-content-between pt-3 form-control gap-1"
    const itemNameClassName = 'col-5 small text-begin'
    const itemCostClassName = 'col-2 small text-end'
    const [addons, setAddons] = useState([
        { name: 'st_100', value: '100', price: 0.59, price_id: '' },
        { name: 'st_200', value: '200', price: 1.00, price_id: '' },
        { name: 'st_300', value: '300', price: 1.39, price_id: '' },
        { name: 'db_3', value: '3', price: 0.89, price_id: '' },
        { name: 'db_5', value: '300', price: 1.0, price_id: '' },
        { name: 'db_10', value: '300', price: 1.79, price_id: '' },
        { name: 'sd_10', value: '10', price: 1.0, price_id: '' },
        { name: 'sd_30', value: '30', price: 2.0, price_id: '' },
        { name: 'sd_50', value: '30', price: 0.10, price_id: '' },
        { name: 'in_100', value: '100', price: 0.59, price_id: '' },
        { name: 'in_1000', value: '300', price: 5.59, price_id: '' },
        { name: 'in_300', value: '300', price: 1.39, price_id: '' }
    ])

    const [selectedSt, setSelectedSt] = useState({})
    const [selectedDb, setSelectedDb] = useState({})
    const [selectedSd, setSelectedSd] = useState({})
    const [selectedIn, setSelectedIn] = useState({})

    useEffect(() => {
        onChange && onChange([selectedSt, selectedDb, selectedSd, selectedIn])
    }, [selectedDb, selectedIn, selectedSt, selectedSd])

    const getPlanAddons = async() =>{
        try {
            const response = await axios.get('/web/plan-addons')
            
            setAddons(response?.data)
            
        } catch (error) {
            
        }       
    }

    useEffect(()=>{
        getPlanAddons()
    },[])

    return (

        <section>
            <div className='plan-addons-matrix'>

                <div className='plan-addons-listing'>
                    <p>
                        {t('webhosting.plans.items.storage')}
                    </p>
                    <div>

                        {addons.map((a) => (
                            a.name.includes('st_') &&
                            <div
                                className={selectedSt?.name === a.name && 'selected'}
                                onClick={() => { selectedSt?.name === a.name ? setSelectedSt({}) : setSelectedSt(a) }}
                            >
                                <MDBIcon icon='hdd' />
                                <p>+ {a?.value} GB</p>
                                <p>+ {a?.price} {t('webhosting.currency.symbol')} </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='plan-addons-listing'>
                <p>
                        {t('webhosting.plans.items.databases')}
                    </p>

                    <div>
                        {addons.map((a) => (
                            a.name.includes('db_') &&
                            <div
                                className={selectedDb?.name === a.name && 'selected'}
                                onClick={() => { selectedDb?.name === a.name ? setSelectedDb({}) : setSelectedDb(a) }}
                            >

                                <MDBIcon icon='database' />
                                <p>+ {a?.value} </p>
                                <p>+ {a?.price} {t('webhosting.currency.symbol')} </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='plan-addons-listing'>
                <p>
                        {t('webhosting.plans.items.subdomains')}
                    </p>

                    <div>
                        {addons.map((a) => (
                            a.name.includes('sd_') &&
                            <div
                                className={selectedSd?.name === a.name && 'selected'}
                                onClick={() => { selectedSd?.name === a.name ? setSelectedSd({}) : setSelectedSd(a) }}
                            >
                                <MDBIcon icon='certificate' />
                                <p>+ {a?.value}</p>
                                <p>+ {a?.price} {t('webhosting.currency.symbol')} </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='plan-addons-listing'>
                <p>
                        {t('webhosting.plans.items.inboxes')}
                    </p>
                    <div>
                        {addons.map((a) => (
                            a.name.includes('in_') &&
                            <div
                                className={selectedIn?.name === a.name && 'selected'}
                                onClick={() => { selectedIn?.name === a.name ? setSelectedIn({}) : setSelectedIn(a) }}
                            >
                                <MDBIcon icon='envelope' />
                                <p>+ {a?.value} </p>
                                <p>+ {a?.price} {t('webhosting.currency.symbol')} </p>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </section>
    )

}