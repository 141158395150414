import { useArticle } from "features/Blog/hooks/useArticle"
import { MDBContainer } from "mdb-react-ui-kit"
/* 
    This Component Renders the given blog article 
    
*/
export const BlogArticle = ({ articleId }) => {

    const [article] = useArticle(articleId)

    return (
        <>
            {
                <MDBContainer className='row'>
                    <div className='d-flex justify-content-between align-items-center '>
                        <h4>{article?.title}</h4>
                        <div className='small text-end'>
                            <p className='m-0'>{article?.author}</p>
                            <p className='small'>{article?.published_date} </p>
                        </div>
                    </div>
                    <div>
                        <img className='thumbnail col-6 p-5' src={article?.image} />
                        <p className='text-muted col-6'>{article?.description}</p>
                    </div>
                    <>
                        <div dangerouslySetInnerHTML={{ __html: article?.content }}>
                        </div>
                    </>
                </MDBContainer>
            }
        </>
    )
}
