import { Route, Routes }  from "react-router-dom";
import { BaseLayout } from './components/Layout';




/* Import our Pages */
import Webhosting from './pages/Webhosting';
import NotFound from './pages/NotFound';
import Home from './pages/Home'
import { BlogStartPage, BlogCategoryPage, BlogArticlePage } from "features/Blog";
import Domains from './pages/Domains';

/* Account Creation, Login and Settings */
import { SocialCallback, LoginPersistance, PrivateRoute, LoginPage, RegisterPage, AccountRecoveryPage } from './features/Authentication';

import ProfileView from './pages/Profile';

/* Webhosting Panel and Subscriptions */
import HostingPanel, { HostingPanelLayout, HPApps, HPDatabases, HPFileManager } from './pages/HostingPanel'
import WHSubscriptions from './pages/WebhostingSubscriptions';

/* Webhosting Shop */
import { WHCheckoutDetailsPage, WHCheckoutPaymentsPage, WHLandingPage, WHPlanDetailsPage } from "./features/Webhosting";

function App() {
  return (
    <Routes>
      <Route element={<LoginPersistance />}>
        <Route path="/" element={<BaseLayout />} >

          {/* Public Basic Routes */}
          <Route exact path="/" element={<Home />} />
          <Route path="/terms" element={<Home />} />
          <Route path="/privacy" element={<Home />} />
          <Route path="/contact" element={<Home />} />
          <Route path="/aboutus" element={<Home />} />
          <Route path="/products" element={<Home />} />

          {/* Public Blog Routes */}
          <Route exact path="/blog" element={<BlogStartPage />} />
          <Route path="/blog/categories/:catId/" element={<BlogCategoryPage />} />
          <Route path="/blog/articles/:articleId/" element={<BlogArticlePage />} />

          {/* Public WebHosting Routes */}
          <Route exact path="/webhosting" element={<WHLandingPage />} />
          <Route path="/webhosting/:planId/" element={<WHPlanDetailsPage /> } />
          <Route path="/webhosting/:planId/subscribe" element={<WHCheckoutDetailsPage />} />

          {/* Public Domain Routes */}
          <Route exact path="/domains" element={<Domains />} />
          <Route exact path="/domains/order" element={<Domains />} />
          <Route exact path="/domains/orders/:orderId/ownerinformation" element={<Domains />} />
          <Route exact path="/domains/orders/:orderId/billinginformation" element={<Domains />} />
          <Route exact path="/domains/orders/:orderId/checkout" element={<Domains />} />
           {/* Public Auth Routes
            <Route exact path="/" element={ <AuthenticationRoutes /> } /> // doesnt work as expected
          */}
          {/* Public Auth Routes  */}
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/register" element={<RegisterPage />} />
          <Route exact path="/recover" element={<AccountRecoveryPage />} />
          <Route path="/social/:provider/callback/" element={<SocialCallback />} />
         

          {/* Not Found Route  */}
          <Route path="/*" element={<NotFound />}
          />

          {/* Protected Routes  */}
          <Route element={<PrivateRoute />}>
            {/* Protected Profile Routes */}
            <Route exact path="/profile/" element={<ProfileView />} />
            <Route exact path="/profile/settings" element={<LoginPage />} />
            <Route exact path="/profile/billingaddresses" element={<LoginPage />} />
            <Route exact path="/profile/deleteaccount" element={<LoginPage />} />
            <Route exact path="/profile/export" element={<LoginPage />} />

            {/* Protected Domain Routes */}
            <Route exact path="/domains/mydomains" element={<Domains />} />
            <Route exact path="/domains/mydomains/:domainId" element={<Domains />} />
            <Route exact path="/domains/mydomains/:domainId/settings" element={<Domains />} />
            <Route exact path="/domains/mydomains/:domainId/dnssettings" element={<Domains />} />
            <Route exact path="/domains/mydomains/:domainId/billing" element={<Domains />} />

            {/* Protected Webhosting Routes */}
            {/* Checkout Payments Page */}
            <Route path="/webhosting/subscriptions/:subId/checkout" element={<WHCheckoutPaymentsPage />} />
            {/* Subscription Settings */}
            <Route path="/webhosting/subscriptions/" element={<WHSubscriptions />} />
            <Route path="/webhosting/subscriptions/:subId/" element={<Webhosting />} />
            <Route path="/webhosting/subscriptions/:subId/settings" element={<Webhosting />} />
            <Route path="/webhosting/subscriptions/:subId/hostingpanel" element={<Webhosting />} />
            <Route path="/webhosting/subscriptions/:subId/billing" element={<Webhosting />} />

            {/* Protected Webhosting Panel Routes 

            Not specified yet. 

           */}
            <Route path="/panel/" element={<HostingPanelLayout />} >
              <Route path="/panel/" element={<HostingPanel />} />
              <Route path="/panel/databases/" element={<HPDatabases />} />
              <Route path="/panel/files/" element={<HPFileManager />} />
              <Route path="/panel/applications/" element={<HPApps />} />
              <Route path="/panel/git-settings/" element={<Webhosting />} />
              <Route path="/panel/composer/" element={<Webhosting />} />
              <Route path="/panel/dns/" element={<Webhosting />} />
              <Route path="/panel/php-settings/" element={<Webhosting />} />
              <Route path="/panel/php-composer/" element={<Webhosting />} />
              <Route path="/panel/nginx-and-apache/" element={<Webhosting />} />
              <Route path="/panel/email-settings/" element={<Webhosting />} />
              <Route path="/panel/ssl/" element={<Webhosting />} />
              <Route path="/panel/statistics/" element={<Webhosting />} />
            </Route>
          </Route>

        </Route>
      </Route>
    </Routes>
  );
}

export default App;

