import { useEffect, useState } from "react";
import { MDBTabs, MDBTabsContent, MDBTabsLink, MDBTabsPane, MDBTabsItem, MDBIcon, MDBContainer } from 'mdb-react-ui-kit'

export const TabWidgetItem = ({ tabTitle, tabIcon, children }) => {
    return (
        <MDBContainer fluid>
        { children }
        </MDBContainer>
    )

}

export const TabWidget = ({ children }) => {

    const [activeTab, setActiveTab] = useState('tabWidgetItem0');

    const activateTab = (value) => {
        if (value === activeTab) {
            return;
        }
        setActiveTab(value);
    };

 
    return (
        <>
                <MDBTabs fill>
                    {/* Map the titles of the children */
                        children.length ? (
                            children.map((child, index) => (
                                <MDBTabsItem key={index}>
                                    <MDBTabsLink onClick={() => activateTab(`tabWidgetItem${index}`)} active={activeTab === `tabWidgetItem${index}`}>
                                        <MDBIcon icon={child.props.tabIcon} /> {child.props.tabTitle}
                                    </MDBTabsLink>
                                </MDBTabsItem>
                            ))) : (<></>)
                    }
                </MDBTabs>
                <MDBTabsContent>

                    {/* Map the titles of the children */
                        children.length ? (
                            children.map((child, index) => (
                                <MDBTabsPane key={index} show={(activeTab === `tabWidgetItem${index}`)}>
                                    { child}
                                </MDBTabsPane>
                            ))) : (<></>)
                    }
                </MDBTabsContent>
                
        </>
    )
}