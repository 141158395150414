import { useEffect, useState } from "react"
import axios from "api/axios"
import { LoadingOrContent } from "components/ui/Spinners/SpinningCowHead"
import { SocialLoginButton } from "../SocialLoginButton/SocialLoginButton"

export const SocialLogin = ({ title }) => {
    const [providers, setProviders] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getProviders = async () => {
            try {
                const response = await axios.get('auth/providers/')
                const _providers = response?.data?.results
                setProviders(_providers)
            } catch (err) {
                /* Ignore errors */
                console.error(`SOCIAL LOGIN - Error while fetching Social Providers:
                    ${err}
                 `)
            } finally {
                setLoading(false)
            }
        }
        getProviders()
    }, [])

    return (
        <div>
            <h3 className="h6 text-center mb-3">{title}</h3>
            <div
                className="d-flex flex-row justify-content-center align-items-center col-12"
                style={{ minHeight: '4rem' }}
            >
                <LoadingOrContent loading={loading}>
                    {   /* Map the providers to buttons */
                        providers.map(provider => (
                            <SocialLoginButton
                                key={provider.id}
                                className={`btn text-white m-1 ${provider.provider}`}
                                provider={provider.provider}
                                client_id={provider.client_id}
                            />
                        ))
                    }
                </LoadingOrContent>
            </div>
            <hr className='hr hr-blurry' />
        </div>
    )
}