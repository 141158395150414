
import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import axios from "../../../../api/axios"
import useCsrf from "../../../../hooks/useCsrf"
import {useAuth} from "features/Authentication"
import useCookies from "../../../../hooks/useCookies"
import { MDBContainer } from "mdb-react-ui-kit"
import { useNavigate } from "react-router-dom"


export const SocialCallback = () => {
    /* 
    
    This is the Callback Component that will receive the oauth2 code and state.

                
    */
    const [loading, setLoading] = useState(true)
    const { provider } = useParams()
    const [searchParams] = useSearchParams()
    const [code, setCode] = useState('')    
    const [state, setState] = useState('')
    const { setAuth } = useAuth()
    const navigate = useNavigate()    
    const from =  '/' // as we lost the app state, redirect the user to home, later /profile
    const [csrf] = useCsrf()
    const [errMsg, setErrMsg] = useState('')
    const {setCookie,getCookie} = useCookies()

    useEffect(() => {
        // get the values of state and code from the url options
        setState(searchParams.get('state'))
        setCode(searchParams.get('code'))
    }, [])

    useEffect(() => {
        const handleRegister = async () => {
            const authUrl = `auth/${provider}/`
            try {
                const response = await axios.post(authUrl,
                    JSON.stringify({
                        code: code
                    }), {
                    headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrf },
                    withCredentials: true,
                    mode: 'same-origin'
                })
                // Get the Token => Will change when implementing JWT
                const accessToken = response?.data?.access_token
                setCookie('refresh', response?.data?.refresh_token, 24*60) // we need to send this due to simpleJWT settings 

                // Save the global auth state       
                setAuth({ accessToken: accessToken, isAuthenticated: true })                
                // if everything is good, we redirect the user to the location stored in from
                navigate(from, { replace: true })
            } catch (err) {
                // Display a message. We will not provide details to make it harder for potentil adverssaries. 
                setErrMsg('Ooops. Something went wrong.')
            } finally {
                setLoading(false)
            }
        }
        /* Make sure we created the state by comparing it to the global auth.state */
        let cookieState = getCookie(`state${provider}`);
        if (state === cookieState ) {
            handleRegister()
        }
    }, [code])

    return (
        <LoadingOrContent loading={loading}>
            <MDBContainer>
                <h4>Something went wrong!</h4>
                <p className="note note-error">{ errMsg }</p>
            </MDBContainer>
        </LoadingOrContent>
    )

}