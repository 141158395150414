import { MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit"
import { BlogCategoriesListItem, useBlog } from "features/Blog"

export const BlogCategoriesList = () => {
    const [categories, articles, getArticlesByCategory] = useBlog()

    return (
        <MDBListGroup id="categoriesList" className='rounded-1 shadow-5 col-12'>
            <MDBListGroupItem className='bg-primary text-light text-center opacity-50'>
                <h6>All Categories</h6>
                <p className="text-muted small">{`${articles?.length} Articles`}</p>
            </MDBListGroupItem>
            {
                categories.map(category => (
                    <BlogCategoriesListItem
                        key={category.id}
                        catId={category.id}
                        name={category.name}
                        articles={getArticlesByCategory(category.name)}
                        description={category.description}
                    />
                ))
            }
        </MDBListGroup>
    )
}
