import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
  MDBBtn,
  MDBCol,
  MDBRow
} from 'mdb-react-ui-kit';

export default function Footer() {
  return (
    <MDBFooter className='text-center text-white mt-5 bg-dark' >
       <section className='d-flex justify-content-end p-4 border-bottom'>       

        <div>
      {/*   <a href='' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='facebook-f' />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='twitter' />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='google' />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='instagram' />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='linkedin' />
          </a>*/}
          <a href='https://github.com/codingcowde' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='github' />
          </a>
        </div>
      </section>
      <section>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon color='secondary' icon='bell' className='me-3' />
                Coding Cow
              </h6>
              <p>
              We&apos;ve designed our services to be easy to use, secure and reliable, so you can focus on developing your website and applications without worrying about technical issues.


              </p>
            </MDBCol>

            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Our Products</h6>
              <p>
                <a href='#!' className='text-reset'>
                  Webhosting
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Domains
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  PyPleskI
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Django Password Manager
                </a>
              </p>
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>          
               <p>
                <a href='#!' className='text-reset'>
                  Account Settings
                </a>
              </p>                
             
              <p>
                <a href='#!' className='text-reset'>
                  Help Center
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Impressum
                </a>
              </p>
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon color='secondary' icon='home' className='me-2' />
                Bournemouth BH9 2TA House 92
              </p>
              <p>
                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                contact@codingcow.de
              </p>
              <p>
                <MDBIcon color='secondary' icon='phone' className='me-3' /> + 44 7464 550552
              </p>
             
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-3 m-0 bg-dark'>
        © 2023 Copyright:
        <a className='text-white' href='http://localhost:3000/'> codingcow.de
         
        </a>
      </div>
    </MDBFooter>
  );
}