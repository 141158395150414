import { PasswordResetForm } from "features/Authentication"

export const AccountRecoveryPage = () => {

    return(
        <PasswordResetForm />
    )

}

