/* 
    This is the Plan Details Page
*/

import {  MDBIcon, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

/* Our own imports */
import { LoadingOrContent } from 'components/ui';

import { NavLink } from 'react-router-dom';

import { WHSelectedPlanWidget, WHOtherPlansWidget, usePlans } from 'features/Webhosting';
import { useTranslation } from 'react-i18next';

import SlimGallery from 'features/SlimGallery';

/* Refactor to use stripe hooks */
export const WHPlanDetailsPage = () => {
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation()


    /* Plan related */    
    const [plans, selectedPlan, otherPlans] = usePlans()
    
    useEffect(()=>{
        plans && setLoading(false)
    },[plans])



    return (

        <section className='row p-0 m-0'>
            <h3 className="mb-4 ">
                <MDBIcon fas icon="cloud" style={{ marginRight: '0.3rem' }} />
                {t('webhosting.pages.PlanDetails.title')}
                <a className="link" id="titleLinkPlanName" style={{ marginLeft: '0.3rem' }}>
                    {selectedPlan?.name}
                </a>
            </h3>
            <LoadingOrContent loading={loading}>
                
                { plans?.length && <>
                <MDBCol sm='12' md='4'>
                    <WHSelectedPlanWidget plan={selectedPlan} />
                    <WHOtherPlansWidget plans={otherPlans} />
                </MDBCol>
                <MDBCol sm='12' md='8'>
                    <MDBRow>
                        <h6> {selectedPlan?.name} - {t('webhosting.pages.PlanDetails.sub-title')}</h6>
                        <hr className='hr' />
                        <section>

                            <p>{t(`webhosting.plans.${selectedPlan?.plan_internal_name}.longDescription.introduction`)}</p>
                            <ul>
                                <li>{t(`webhosting.plans.${selectedPlan?.plan_internal_name}.longDescription.listing.one`)} </li>
                                <li>{t(`webhosting.plans.${selectedPlan?.plan_internal_name}.longDescription.listing.two`)} </li>
                                <li>{t(`webhosting.plans.${selectedPlan?.plan_internal_name}.longDescription.listing.three`)} </li>
                            </ul>

                        </section>
                        <h6> {selectedPlan?.name} - {t('webhosting.pages.PlanDetails.screenshots')}</h6>
                        <hr className='hr' />
                        <section>
                            <SlimGallery images={
                                [
                                    { url: "https://picsum.photos/800/600", description: "The Admin panel", title: "Admin" },
                                    { url: "https://picsum.photos/800/600", description: "Desciption", title: "Template" },
                                    { url: "https://picsum.photos/800/600", title: "Editor" }

                                ]
                            } />

                            <br />
                        </section>
                        <section>
                            <h6>{/* Webhosting Features */}Addons </h6>
                            <p>
                                {/* blsblalbalbalblabalbaba */}
                                You can add additional storage, databases, subdomains and email accounts to your susbcription.

                            </p>
                            <br />

                            <NavLink
                                to={`/webhosting/${selectedPlan?.id}/subscribe/`}
                                className='text-center btn btn-success opacity-70'
                            >
                                {t('webhosting.pages.PlanDetails.call-to-action')} {selectedPlan?.plan_price_monthly} {t('webhosting.currency.symbol')}
                            </NavLink>

                        </section>


                    </MDBRow>


                    <MDBRow>


                    </MDBRow>
                </MDBCol>
                
                
                </>
}
            </LoadingOrContent>
        </section>
    )
}

