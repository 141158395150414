import { MDBCard, MDBCardBody, MDBCardFooter, MDBCardSubTitle, MDBCardImage } from "mdb-react-ui-kit"
import { NavLink } from "react-router-dom"

export const BlogArticleCard = ({ id, title, description, author, img, publishedOnDate }) => {
    return (
        <>
            <div className='col-md-10 col-xl-11 col-12 mb-3'>
                <MDBCard className='border rounded-2 overflow-hidden'>
                    <div className='bg-image rounded-0'>
                        <MDBCardImage src={img} position='top' className='img-fluid rounded-0' style={{ height: '6rem', objectFit: 'cover' }} />
                        <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 200, 0.2)' }} >
                            <div className='d-flex m-3 p-3'>
                                <h5 className='h5 text-uppercase text-white '>{title}</h5>
                            </div>
                        </div>
                    </div>
                    <MDBCardBody>

                        <MDBCardSubTitle className='d-flex justify-content-between small text-muted text-uppercase px-1'>
                            <p>Author: {author}</p>
                            <p>{publishedOnDate}</p>
                        </MDBCardSubTitle>
                        <p>
                            {description}
                        </p>
                    </MDBCardBody>
                    <MDBCardFooter>
                        <NavLink className='btn btn-tertiary' to={`/blog/articles/${id}/`} >Continue reading...</NavLink>
                    </MDBCardFooter>
                </MDBCard>
            </div >
        </>
    )
}