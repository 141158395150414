import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Hero, LoadingOrContent } from "components/ui";
import { MDBCardGroup } from "mdb-react-ui-kit";
import { useCategory, BlogArticleCard, BlogCategoriesList } from "features/Blog";

export const BlogCategoryPage = () => {
    const { catId } = useParams()
    const [categoryDetails, articles] = useCategory(catId)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        articles && categoryDetails && setLoading(false)
    }, [articles, categoryDetails])

    

    /* This needs some documentation. Why is this even here? */
  
    return (
        <>
            <section>
                <LoadingOrContent loading={loading} >
                    <>
                       <Hero title={categoryDetails?.name} caption={categoryDetails?.hero_description} img={categoryDetails?.hero_image} />                   
                        <p>{categoryDetails?.short_description}</p>
                        <hr className="hr " />
                        <div className="d-flex flex-wrap">
                            <div className="col-12 col-md-9 col-xl-10">
                                <MDBCardGroup className='gap-2 justify-content-start' >
                                    {articles && articles.map(article => (
                                        <BlogArticleCard
                                            key={article?.id}
                                            id={article?.id}
                                            title={article?.title}
                                            description={article?.description}
                                            author={article?.author}
                                            publishedOnDate={article?.published_date}
                                            img={article?.image}
                                        />
                                    ))}
                                </MDBCardGroup>
                            </div>
                            <div className="col-12 col-md-3 col-xl-2">
                                <BlogCategoriesList />
                            </div>
                        </div>
                    </>
                </LoadingOrContent>
            </section>
        </>
    )

}
