
import { useState, useEffect } from "react"
import axios from "api/axios"

export const useArticle = (pk) => {
    const [article, setArticle] = useState([])
    const [articleId, setArticleId] = useState(pk || 0)

    useEffect(() => {
        pk && setArticleId(pk)

        const getArticle = async () => {
            try {
                const response = await axios.get(`/blog/articles/${articleId}/`)
                setArticle(response?.data)
                
            } catch (error) {
                console.error('Network error')   
            }
        }
        getArticle()
    }, [])

    return ([article, setArticleId])
}