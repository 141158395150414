import axios from "api/axios"
import { useState, useEffect } from "react"

export const useCategory = (category) => {
    const [articles, setArticles] = useState([])
    const [categoryDetails, setCategoryDetails] = useState({})

    useEffect(() => {
        const getArticles = async () => {
            try {
                const response = await axios.get(`/blog/categories/${category}/articles/`)
                const _articles = response?.data
                setArticles(_articles)

            } catch (err) {
                /* set something to avoid getting stuck in long while nothing will load anymore */
                console.error(`Network Error: ${err}`)
            }
        }
        const getCategoryDetails = async () => {
            try {
                const response = await axios.get(`/blog/categories/${category}/`)
                const _category = response?.data
                setCategoryDetails(_category)

            } catch (err) {
                setCategoryDetails({
                    name: 'Oops! Something went wrong...',
                    short_description: 'Could not retrieve the specified category',
                    hero_descriprion: 'Shit happens!',
                    hero_image: null
                })
                console.error(`Network Error: ${err}`)

            }
        }
        /* Now check if the category is latest  */
        if (category === 'latest') {
            setCategoryDetails({
                name: 'Latest Articles',
                short_description: 'We give our best to publish as frequent as possible. In this category you can find the latest articles.',
                hero_descriprion: 'The latest news from the coding meadow',
                hero_image: null
            })
        } else {
            /* if not getCaption */
            getCategoryDetails()
        }
        /* In any case we want to retrieve articles */

        getArticles()


    }, [category])

    return ([ categoryDetails, articles ])



}