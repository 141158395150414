/* 
    This is the Checkout Payment Page
*/
import { loadStripe } from '@stripe/stripe-js';
import { MDBIcon, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useApiPrivate } from "features/Authentication";

/* STRIPE */
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/StripePayment/StripePayment';


export const WHCheckoutPaymentsPage = () => {
    const { subId } = useParams()
    const api = useApiPrivate()
    const [stripePromise, setStripePromise] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [subscriptionId, setSubscriptionId] = useState('') //referring to the stripe subscription id !

    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    const { t } = useTranslation()
    useEffect(() => {
        const getPublicKey = async () => {
            try {
                // Get the public key from the server
                const response = await api.get('/stripe/configure')
                const key = response.data.private_key

                // perform loadStripe with the key 
                setStripePromise(loadStripe(key))
            } catch (error) {
                setErrorMessage("Oops. Something went wrong. Please try again later.")
                console.log(`could not retrieve the Stripe Public Key Error: ${error}`)
            }
        }

        getPublicKey()

    }, [])


    useEffect(() => {
        const createSubscription = async () => {
            try {
                const response = await api.post('/stripe/create-subscription/', JSON.stringify({
                    // the request data goes here
                    'subscriptionId': subId
                }))
                console.log(response.data)
                const data = response.data
                if (response?.data?.error) {
                    setErrorMessage(response.data.error)
                } else {
                    setClientSecret(data.clientSecret) // the client id for the latest payment intend
                    setSubscriptionId(data.subscriptionId)  // the related subscription id in stripe
                }
            } catch (error) {
                console.log(error)

            } finally {
                setIsLoading(false)
            }
        }
        createSubscription()

    }, [stripePromise])

    const options = {
        clientSecret: clientSecret,
        subscriptionId: subscriptionId
    }

    return (
        <>
            <h2><MDBIcon color='secondary' icon='shopping-cart' /> {t('webhosting.pages.CheckoutPayment.title') /*CCD Webhosting Checkout*/}</h2>
            <br />
            <MDBRow>
                <MDBCol size='12' md='6'>
                    <section>
                        <h4> {t('webhosting.pages.CheckoutPayment.subscription-details-title') /*Your Subscription Details*/}</h4>
                        <br />
                    </section>
                </MDBCol>

                <MDBCol size='12' md='4' >

                    <section>
                        <h4>  {t('webhosting.pages.CheckoutPayment.stripe-title') /*Payment*/} </h4>
                        <p className='small'>
                            {t('webhosting.pages.CheckoutPayment.stripe-disclaimer')
                            /*Your Payment Information for your Subscriptions is taken and stored by STRIPE as our primary payments processor. Read here to learn more about STRIPE.*/}
                        </p>

                        {clientSecret && stripePromise && <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm />
                        </Elements>}
                    </section>
                </MDBCol>
            </MDBRow>
        </>
    )


}