import { MDBBreadcrumb, MDBBreadcrumbItem, MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardGroup, MDBCardHeader, MDBCardText, MDBCol, MDBIcon, MDBInput, MDBInputGroup, MDBListGroup, MDBListGroupItem, MDBNavbar, MDBNavbarBrand, MDBRow, MDBTooltip } from "mdb-react-ui-kit"
import PageClipart from "components/ui/PageClipart/PageClipart"
import panelClipart from "assets/img/clipart/hosting-panel.png"
import learning from "assets/img/clipart/learning.png"
import packages from "assets/img/clipart/packages.png"
import { useNavigate, Outlet, useLocation, NavLink } from "react-router-dom"
import { useEffect, useState } from "react"


export const ToolBar = ({ children, className }) => {
    return (
        <MDBRow className={className + " gap-1 m-0"}>
            {children}
        </MDBRow>
    )
}

export const ToolBarButton = ({ title, tooltip, icon, fab, action }) => {
    return (
        <MDBTooltip
            wrapperProps={{
                color: 'secondary',
                onClick: action,
                style: {
                    width: '4rem',
                    height: '4rem',
                    padding: '0'
                }
            }}

            title={tooltip || title} placement='bottom'>
            {fab ? (<MDBIcon fab icon={icon} />)
                : (<MDBIcon icon={icon} />)
            }
            <br />

            {title}
        </MDBTooltip>

    )
}


export const ToolButton = ({ action, icon, title, tooltip, fab }) => {
    return (
        <MDBTooltip wrapperProps={{ color: 'light', onClick: action, className: 'col-4 col-md-2' }} title={tooltip || title} placement='bottom'>
            {fab ? (<MDBIcon fab icon={icon} className="rounded-circle bg-primary p-3 text-white text-bg-primary col-7 col-xl-5 shadow" size='2x' />)
                : (<MDBIcon icon={icon} className="rounded-circle bg-primary p-3 text-white text-bg-primary col-7 col-xl-5 shadow" size='2x' />)
            }

            <p className="mt-1 col-12 fw-bold">
                <br />
                {title}</p>
        </MDBTooltip>
    )
}

// We gonna use the action prop to route to the subpages 


export const HostingPanelLayout = () => {
    let location = useLocation()
    const [breadcrumb, setBreadCrumb] = useState([])
    const [title, setTitle] = useState('')

    useEffect(() => {
        const _crumbles = location.pathname.split('/')
        const crumbles = _crumbles.filter((str) => (str != ''))

        setBreadCrumb(crumbles)
        crumbles[1] ? setTitle(crumbles[1]) : setTitle('Admin Panel')

    }, [location])

    return (
        <>
            <h3 className="mb-2 text-capitalize"> {title} <a className="link text-lowercase">codingcow.de</a></h3>
            <MDBBreadcrumb aria-label="breadcrumb" className="text-capitalize">
                {breadcrumb.map((crumble, index) => (
                    index === breadcrumb.length - 1
                        ? <MDBBreadcrumbItem key={index} aria-label='current page'>{crumble} </MDBBreadcrumbItem>
                        : index === 0
                            ? <MDBBreadcrumbItem key={index} ><NavLink to={``}>{crumble}</NavLink></MDBBreadcrumbItem>
                            : <MDBBreadcrumbItem key={index} ><NavLink to={`${crumble}/`}>{crumble}</NavLink></MDBBreadcrumbItem>
                ))}
            </MDBBreadcrumb>
            <section>
                <Outlet />
            </section>
            <br />
            <PageClipart
                src={learning}
                caption='Find out more about how to optimize your sites performance, check our help center [link to hc]'
            />
            <br />
        </>
    )
}


const HostingPanel = () => {
    const navigate = useNavigate()


    return (
        <>
            <br />
            <PageClipart
                src={panelClipart}
                caption='Configure your Server in a few simple steps. if you get stuck, check our help center [link to hc]'
            />
            <br />
            <section className="m-2">
                <h4 className="h6 "> <MDBIcon icon='screwdriver' /> Tools</h4>
                <hr className="hr" />
                <MDBRow>
                    <ToolButton action={() => { navigate('/panel/databases/', { replace: false }) }} icon='database' title='Databases' tooltip='Add, assign or delete databases.' />
                    <ToolButton action={() => { navigate('/panel/files/', { replace: false }) }} icon='folder' title='Files' tooltip='Upload and manage your files.' />
                    <ToolButton action={() => { navigate('/panel/applications/', { replace: false }) }} fab icon="app-store" title='Apps' tooltip='Install an Application to the server.' />
                    <ToolButton fab icon='git' title='Git' tooltip='Manage your Git Repositories.' />
                    <ToolButton icon='boxes' title='PHP Composer' tooltip='Add or remove Composer Packages.' />
                </MDBRow>
            </section >
            <br />
            <section className="m-2">
                <h4 className="h6 "><MDBIcon icon='cloud' /> Hosting Settings</h4>
                <hr className="hr" />
                <MDBRow>
                    <ToolButton icon='globe' title='Web Access' />
                    <ToolButton icon='sitemap' title='FTP Access' />
                    <ToolButton icon='user-lock' title='Web User' />
                    <ToolButton icon='lock' title='Locked Folders' />
                    <ToolButton fab icon='php' title='PHP Settings' />
                    <ToolButton fab icon='node-js' title='NodeJS Settings' />
                    <ToolButton icon='toolbox' title='Apache & Nginx' />
                    <ToolButton icon='flag' title='DNS Settings' />
                    <ToolButton icon='lock' title='SSL/TLS' />
                </MDBRow>
            </section>
            <br />
            <section className="m-2">
                <h4 className="h6 "><MDBIcon icon='envelope' /> Email Settings</h4>
                <hr className="hr" />
                <MDBRow>
                    <ToolButton icon='user-alt' title='Accounts' />
                    <ToolButton icon='mail-bulk' title='Mail Server' />
                    <ToolButton fab icon='mailchimp' title='Mailchimp' />
                </MDBRow>
            </section>
            <br />
            <section className="m-2 ">
                <h4 className="h6 "><MDBIcon icon='temperature-low' /> Protocolls & Statistics</h4>
                <hr className="hr" />
                <MDBRow>
                    <ToolButton icon='tachometer-alt' title='Webstats' />
                    <ToolButton icon='chart-pie' title='Usage' />
                    <ToolButton icon='file-archive' title='Protocolls' />
                </MDBRow>
            </section>
            <br />

          
            <br />
        </>
    )
}
export default HostingPanel

/* DATABASES */
export const HPDatabases = () => {
    const [searchTerm, setSearchTerm] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [databases, setDatabases] = useState([])

    useEffect(() => {

    }, [searchTerm])

    useEffect(() => {

    }, [searchResults])

    return (
        <>
            <br />
            <PageClipart
                src={panelClipart}
                caption='Create and manage your databases.'
            />
            <br />
            <section>
                <MDBNavbar className="p-3">
                    <h5>
                        All Databases for codingcow.de
                    </h5>
                    <div className='d-flex align-items-center gap-2 pe-2'>
                        <MDBInputGroup noBorder textAfter={<MDBIcon fas icon='search' />} >
                            <MDBInput className="form-control rounded" type='search' label='Search ...' />
                        </MDBInputGroup>

                        <MDBBtn color='outline-primary' ><MDBIcon icon='plus-circle' /> Add Database</MDBBtn>
                    </div>
                </MDBNavbar>
                <br />
                <MDBListGroup>
                    <DatabaseListItem />
                    <DatabaseListItem />
                    <DatabaseListItem />
                </MDBListGroup>
                <br />
            </section>
        </>
    )
}


export const DatabaseListItem = ({ database }) => {
    return (
        <MDBListGroupItem>
            <MDBRow>
                <MDBCol>
                    <p className="fw-bold">[DATABASE_NAME] </p>
                    <p className="text-muted">[DATABASE_TYPE] </p>
                    <p className="text-reset">[SERVER:PORT]</p>
                </MDBCol>
                <MDBCol>
                    <p className="fw-bold">Credentials</p>
                    <p className="text-muted">[USER] </p>
                    <p className="text-muted">[PWD] </p>

                </MDBCol>
                <MDBCol>
                    <p className="fw-bold">Credentials</p>
                    <p className="text-muted">[USER] </p>
                    <p className="text-muted">[PWD] </p>

                </MDBCol>
                <MDBCol className="col-12 col-md-4 col-xl-5">
                    <ToolBar>
                        <ToolBarButton icon='tools' title='admin' />
                        {/* if we want to use the phpMyadmin bundled with plesk, 
                        we need to implement sso for phpmyadmin and redirect after obtaining the plesk session cookie to 
                         success_redirect_url=phpMyAdmin/index.php?db=[DATABASE NAME]

                         Alternatively, we can host a own instance
                        */}
                        <ToolBarButton icon='arrow-down' title='export' />
                        <ToolBarButton icon='arrow-up' title='import' />
                        <ToolBarButton icon='check' title='check up' />
                        <ToolBarButton icon='clone' title='Clone' />
                        <ToolBarButton icon='trash' title='Delete' />
                    </ToolBar>
                </MDBCol>
            </MDBRow>

        </MDBListGroupItem>
    )

}

/* FILEMANAGER  */

export const HPFileManager = () => {
    const [currentDir, setCurrentDir] = useState('')
    const [currentFileList, setCurrentFileList] = useState([{ name: 'index.html', type: 'html', size: '800', link: '#' }, { name: 'style.css', type: 'html', size: '800', link: '#' }])
    const [openFiles, setOpenFiles] = useState([])
    const [openDirs, setOpenDirs] = useState([])

    return (
        <>
            <MDBNavbar className="p-3 ">
                <MDBRow className="col-12">

                    <MDBCol size='8'>
                        <MDBInputGroup noBorder textAfter={<MDBIcon fas icon='search' />} >
                            <MDBInput className="form-control rounded" type='search' label='Search in current directory ...' />
                        </MDBInputGroup>
                        <br />
                        <MDBBreadcrumb>
                            <MDBBreadcrumbItem><MDBIcon icon='folder-open' /></MDBBreadcrumbItem>
                            <MDBBreadcrumbItem>Previous Folder</MDBBreadcrumbItem>
                            <MDBBreadcrumbItem>Current Folder</MDBBreadcrumbItem>

                        </MDBBreadcrumb>
                    </MDBCol>
                    <MDBCol size='4' >
                        <ToolBar className={'justify-content-end mt-2'} >
                            <ToolBarButton title='upload' icon='upload' />
                            <ToolBarButton title='new file' icon='file' />
                            <ToolBarButton title='new dir' icon='folder' />
                            <ToolBarButton title='refresh' icon='sync' />
                        </ToolBar>
                    </MDBCol>
                </MDBRow>



            </MDBNavbar>
            <br />


            <MDBListGroup>
                <MDBListGroupItem>
                    <a href='#' className="d-flex text-reset justify-content-between">
                        <MDBCol size='3'>
                            <MDBIcon icon='folder-open' /><b> ..</b>
                        </MDBCol>
                        <p>-rw-r-xrw </p>
                        <p>[owner]</p>
                        <MDBCol className="d-flex gap-1" size='3'>
                            <MDBBtn color='outline-primary' ><MDBIcon icon='copy' /></MDBBtn>

                            <MDBBtn color='outline-primary' ><MDBIcon icon='trash' /></MDBBtn>
                        </MDBCol>
                    </a>
                </MDBListGroupItem>
                {currentFileList.map((file) => (
                    file.type === 'dir' ? <FileListItem folder name={file.name} link={file.link} /> : <FileListItem name={file.name} />
                ))}

            </MDBListGroup>
        </>
    )
}

export const FileListItem = ({ folder, name, size, type }) => {

    return (
        <><MDBListGroupItem >
            <a href='#' className="d-flex text-reset justify-content-between">
                <MDBCol size='3'>
                    {
                        folder
                            ? (<><MDBIcon icon='folder' /> <b> {name || 'dir'}</b></>)
                            : (<><MDBIcon icon='file' /> <b> {name || 'file'}</b></>)
                    }
                </MDBCol>
                <p>-rw-r-xrw </p>

                <p>[owner]</p>
                <MDBCol className="d-flex gap-1" size='3'>
                    <MDBBtn color='outline-primary'><MDBIcon icon='copy' /></MDBBtn>
                    <MDBBtn color='outline-primary'><MDBIcon icon='edit' /></MDBBtn>
                    <MDBBtn color='outline-primary' ><MDBIcon icon='trash' /></MDBBtn>
                </MDBCol>
            </a>
        </MDBListGroupItem>
        </>
    )
}
/* APPS Installer */

export const HPApps = () => {
    const [searchTerm, setSearchTerm] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [apps, setApps] = useState([
        { name: 'joomla', description: 'Joomla is an open source content management system.', icon: 'joomla' },
        { name: 'magento', description: 'Magento is an powerfull open source content and customer relations management system.', icon: 'magento' },
        { name: 'drupal', description: 'Drupal is an powerfull open source content and customer relations management system.', icon: 'drupal' },
        { name: 'wordpress', description: ' Wordpress is an open source content management system with Blogging in mind. ', icon: 'wordpress' }


    ])

    

    useEffect(() => {
        const _results = []
        apps.forEach((app) => {
            if (app.name.toLowerCase().includes(searchTerm.toLowerCase()) || app.description.toLowerCase().includes(searchTerm.toLowerCase())) {
                _results.push(app)
            }
            setSearchResults(_results)
        })
    }, [searchTerm])

    return (
        <>
            <br />
            <PageClipart
                src={packages}
                caption='Install a new App on your site.'
            />
            <br />
            <section>
                <MDBNavbar className="p-3">
                    {
                        searchTerm ? (<h5> {searchResults.length} Apps for {searchTerm} </h5>) : (<h5>All Apps </h5>)
                    }

                    <div className='d-flex align-items-center gap-2 pe-2'>
                        <MDBInputGroup noBorder textAfter={<MDBIcon fas icon='search' />} >
                            <MDBInput
                                className="form-control rounded"
                                type='search' value={searchTerm}
                                onChange={(e) => { setSearchTerm(e.target.value) }}
                                label='Search Apps ...' />
                        </MDBInputGroup>
                    </div>
                </MDBNavbar>
                <br />
                <MDBListGroup>
                    {
                        !searchTerm ? (apps.map((app, index) => (
                            <AppListItem key={index} icon={app.icon} name={app.name} description={app.description} />
                        )))
                            : (!searchResults.length ? (<MDBListGroupItem> 0 Results for your search</MDBListGroupItem>)
                                : (searchResults.map((app, index) => (
                                    <AppListItem key={index} icon={app.icon} name={app.name} description={app.description} />
                                ))))
                    }


                </MDBListGroup>
                <br />
            </section>
        </>
    )
}

export const AppListItem = ({ icon, description, name }) => {
    return (
        <MDBListGroupItem>
            <a href='#' className="d-flex text-reset">
                <MDBCol size='3'>
                    <MDBIcon fab icon={icon} /> <b className="text-capitalize"> {name} </b>
                </MDBCol>
                <MDBCol size='7'>
                    {description}
                </MDBCol>
                <MDBCol className="justify-content-end">
                    <MDBBtn color="link" >Install now!</MDBBtn>
                </MDBCol>

            </a>
        </MDBListGroupItem>
    )
}

/* GIT */

/* COMPOSER  */
