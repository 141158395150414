const useCookies = () => {
  
    const setCookie = (cookieName, cookieValue, expiresInMinutes) => {
     
        const d = new Date();
        d.setTime(d.getTime() + (expiresInMinutes * 60 * 1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
      }
      
      const getCookie = (cookieName) => {
        let name = cookieName + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }

      return(
        {getCookie,setCookie}
      )
}
export default useCookies