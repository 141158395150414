/* 
    Refactor to use hooks    
    import {useStripe, useElements, PaymentElement, Elements} from '@stripe/react-stripe-js';

    Hooks and context are not needed

    What we should instead: 

    Make StripeCheckout a feature and keep all stripe related things in there
    Make the StripeCheckout more flexible to allow reuse
*/
import {loadStripe} from '@stripe/stripe-js';
import {  MDBIcon, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import React, { useState, useEffect } from 'react';
import { NavLink, useParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import {useApiPrivate} from "features/Authentication";

/* STRIPE */
import { Elements } from '@stripe/react-stripe-js';

import CheckoutForm from '../StripePayment/StripePayment';


export const StripeCheckout = () => {
    const { subId } = useParams()
    const api = useApiPrivate()
    const [stripePromise, setStripePromise] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [subscriptionId, setSubscriptionId] = useState('') //referring to the stripe subscription id !

    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    useEffect(()=>{
        const getPublicKey = async () => {
            try {
                // Get the public key from the server
                const response = await api.get('/stripe/configure')
                const key = response.data.private_key
    
                // perform loadStripe with the key 
                setStripePromise(loadStripe(key))
            } catch (error) {
                console.log(`could not retrieve the Stripe Public Key Error: ${error}` )
            }
        }       
        
        getPublicKey()

    },[])
    

    useEffect(() => {
        let isMounted = true // for cleanup function
        const createSubscription = async () => {
            try {
                const response = await api.post('/stripe/create-subscription/', JSON.stringify({
                    // the request data goes here
                    'subscriptionId': subId
                }))
                console.log(response.data)
                const data = response.data
                setClientSecret(data.clientSecret) // the client id for the latest payment intend
                setSubscriptionId(data.subscriptionId)  // the related subscription id in stripe

            } catch (error) {
                console.log(error)

            } finally{
                setIsLoading(false)
            }
        }
        createSubscription()


        // cleanup function goes here
        // return ()

    }, [stripePromise])

    const options = {
        clientSecret: clientSecret,
        subscriptionId: subscriptionId
    }

    return (
        <>
            <h2><MDBIcon color='secondary' icon='shopping-cart'  /> webhosting.headings.checkout.h1 {/*CCD Webhosting Checkout*/}</h2>
            <br />
            <MDBRow>
                <MDBCol size='12' md='6'>
                    <section>
                        <h4> webhosting.headings.checkout.subcription-details {/*Your Subscription Details*/}</h4>
                        <br />
                    </section>
                </MDBCol>
               
                <MDBCol size='12' md='4' >

                    <section>
                        <h4> webhostig.headings.checkout  {/*Payment*/} </h4>
                        <p className='small'>
                            webhosting.payment.stripe.disclaimer
                            {/*Your Payment Information for your Subscriptions is taken and stored by STRIPE as our primary payments processor. Read here to learn more about STRIPE.*/}
                            </p>

                        {clientSecret && stripePromise &&  <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm />
                        </Elements>}
                    </section>
                </MDBCol>
            </MDBRow>
        </>
    )


}