/* Example code for use of form validation
 (() => {
        'use strict';

        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.querySelectorAll('.needs-validation');

        // Loop over them and prevent submission
        Array.prototype.slice.call(forms).forEach((form) => {
            form.addEventListener('submit', (event) => {
                if (!form.checkValidity()) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            }, false);
        });
    })();
</script>
*/



/* Components to view, edit and delete addresses in Subscription flows or the profile settings */

import {
    MDBBtn, 
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardGroup,
    MDBCardHeader,
    MDBRadio,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBInput
} from "mdb-react-ui-kit"
import { useCallback, useDeferredValue, useEffect, useState } from "react"
import {useApiPrivate} from "features/Authentication"
import { LoadingOrContent } from "components/ui"

export const DeleteAddressModal = ({ address, show, setShow }) => {
    const [loading, setLoading] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const api = useApiPrivate()
    const url = `/profile/addresses/${address.id}/`

    const handleDelete = () => {
        setLoading(true)
        const deleteAddress = async () => {
            try {
                const response = await api.delete(url)
                setShow(!show)
            } catch (error) {
                console.error(error)
                setErrMsg('Oops. Something went wrong.')
            } finally {
                setLoading(false)
            }
        }
        deleteAddress()
    }

    useEffect(() => {
        if (!show) {
            setErrMsg('')
        }
       
    }, [show])
    return (
        <>
            <MDBModal show={show} setShow={setShow} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Edit Address</MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>Are you sure you want to delete this address?</p>

                            {errMsg ? (
                                <p className="note note-danger">{errMsg} </p>
                            ) : (<></>)
                            }
                        </MDBModalBody>
                        <MDBModalFooter>
                            <LoadingOrContent loading={loading}>

                                <MDBBtn onClick={() => setShow(!show)} color="secondary">Cancel</MDBBtn>
                                <MDBBtn onClick={() => handleDelete()} color="danger">Delete Address</MDBBtn>
                            </LoadingOrContent>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>)
}

export const EditAddressModal = ({ address, show, setShow }) => {
    const [loading, setLoading] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [addressLineOne, setAddressLineOne] = useState('')
    const [addressLineTwo, setAddressLineTwo] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const api = useApiPrivate()
    const url = `/profile/addresses/${address.id}/`
    const handleSave = (e) => {
        setLoading(true)
        const saveAddress = async () => {
            try {
                const response = await api.put(url, JSON.stringify({
                    company_name: companyName,
                    name: name,
                    last_name: lastName,
                    address_line_one: addressLineOne,
                    address_line_two: addressLineTwo,
                    zip_code: zipCode,
                    city: city,
                    state: state,
                    country: country
                }))
                setShow(!show)
            } catch (error) {
                console.error(error)
                setErrMsg('Oops. Something went wrong.')
            } finally {
                setLoading(false)
            }
        }
        saveAddress()
    }

    useEffect(() => {
        if (address?.company_name) {
            setCompanyName(address.company_name)
        }
        setName(address.name)
        setLastName(address.last_name)
        setAddressLineOne(address.address_line_one)
        setAddressLineTwo(address.address_line_two)
        setZipCode(address.zip_code)
        setCity(address.city)
        setState(address.state)
        setCountry(address.country)

        if (!show) {
            setErrMsg('')
        }
    }, [show])

    return (
        <>
            <MDBModal show={show} setShow={setShow} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Edit Address</MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className='row gap-3 m-3 mb-1 mt-1'>
                                <MDBInput name='cname' label='Company Name' inputMode='text' onChange={(e) => setCompanyName(e.target.value)} value={companyName} />
                                <MDBInput name='firstName' label='Name' inputMode='text' onChange={(e) => setName(e.target.value)} value={name} required />
                                <MDBInput name='lastName' label='Last Name' onChange={(e) => setLastName(e.target.value)} value={lastName} required />
                                <MDBInput name='addressLineOne' label='Address Line 1' inputMode='text' onChange={(e) => setAddressLineOne(e.target.value)} value={addressLineOne} required />
                                <MDBInput name='addressLineTwo' label='Address Line 2' inputMode='text' onChange={(e) => setAddressLineTwo(e.target.value)} value={addressLineTwo} />
                                <MDBInput name='zipCode' label='ZIP Code' inputMode='text' onChange={(e) => setZipCode(e.target.value)} value={zipCode} required />
                                <MDBInput name='city' label='City' onChange={(e) => setCity(e.target.value)} value={city} required />
                                <MDBInput name='state' label='State' inputMode='text' onChange={(e) => setState(e.target.value)} value={state} />
                                <MDBInput name='country' label='Country' inputMode='text' onChange={(e) => setCountry(e.target.value)} value={country} required />
                            </div>
                            {errMsg ? (
                                <p className="note note-danger mt-3 mb-0">{errMsg} </p>
                            ) : (<></>)
                            }
                        </MDBModalBody>
                        <MDBModalFooter>
                            <LoadingOrContent loading={loading}>
                                <MDBBtn onClick={() => setShow(!show)} color="secondary">Cancel</MDBBtn>
                                <MDBBtn onClick={() => handleSave()}>Save</MDBBtn>
                            </LoadingOrContent>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}

const AddAddressModal = ({ show, setShow }) => {
    const [loading, setLoading] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [addressLineOne, setAddressLineOne] = useState('')
    const [addressLineTwo, setAddressLineTwo] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const api = useApiPrivate()
    const url = '/profile/addresses/'

    const handleSave = () => {
        setLoading(true)
        const saveAddress = async () => {
            try {
                const response = await api.post(url,
                    JSON.stringify({
                        name: name,
                        last_name: lastName,
                        address_line_one: addressLineOne,
                        address_line_two: addressLineTwo,
                        zip_code: zipCode,
                        city: city,
                        state: state,
                        country: country
                    })
                )
                setShow(!show)
            } catch (error) {
                console.error(error)
                setErrMsg('Oops. Something went wrong.')
            } finally {
                setLoading(false)
            }
        }
        saveAddress()
    }

    useEffect(() => {
        if (!show) {
            setErrMsg('')
        }
    }, [show])

    return (
        <>
            <MDBModal show={show} setShow={setShow} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Address</MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className='row gap-3 m-3 mb-1 mt-1'>
                                <MDBInput name='cname' label='Company Name' inputMode='text' onChange={(e) => setCompanyName(e.target.value)} value={companyName} />
                                <MDBInput name='firstName' label='Name' inputMode='text' onChange={(e) => setName(e.target.value)} value={name} required />
                                <MDBInput name='lastName' label='Last Name' onChange={(e) => setLastName(e.target.value)} value={lastName} required />
                                <MDBInput name='addressLineOne' label='Address Line 1' inputMode='text' onChange={(e) => setAddressLineOne(e.target.value)} value={addressLineOne} required />
                                <MDBInput name='addressLineTwo' label='Address Line 2' inputMode='text' onChange={(e) => setAddressLineTwo(e.target.value)} value={addressLineTwo} />
                                <MDBInput name='zipCode' label='ZIP Code' inputMode='text' onChange={(e) => setZipCode(e.target.value)} value={zipCode} required />
                                <MDBInput name='city' label='City' onChange={(e) => setCity(e.target.value)} value={city} required />
                                <MDBInput name='state' label='State' inputMode='text' onChange={(e) => setState(e.target.value)} value={state} />
                                <MDBInput name='country' label='Country' inputMode='text' onChange={(e) => setCountry(e.target.value)} value={country} required />
                            </div>
                            {errMsg ? (
                                <p className="note note-danger mt-3 mb-0">{errMsg} </p>
                            ) : (<br />)
                            }
                        </MDBModalBody>
                        <MDBModalFooter>
                            <LoadingOrContent loading={loading}>
                                <MDBBtn onClick={() => setShow(!show)} color="secondary">Cancel</MDBBtn>
                                <MDBBtn onClick={() => handleSave()}>Save</MDBBtn>
                            </LoadingOrContent>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}

export const AddressCard = ({ address, selectable, onChange, onSelect }) => {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const toggleDeleteDialog = () => setShowDeleteDialog(!showDeleteDialog)

    const [showEditDialog, setShowEditDialog] = useState(false)
    const toggleEditDialog = () => setShowEditDialog(!showEditDialog)

    useEffect(() => {
        if (!onChange) { return }
        onChange()
    }, [showEditDialog, showDeleteDialog])

    return (
        <>
            <MDBCard style={{ minWidth: '14rem' }} >
                <MDBCardHeader className="small mb-2 card-header " >
                    {
                        selectable && onSelect ? (
                            <MDBRadio
                                name="address"
                                value={address.id}                                
                                label="Use this Address"
                                onChange={onSelect}
                                required
                            />
                        ) : (<br />)
                    }
                </MDBCardHeader >
                <MDBCardBody className="card-body p-1 mb-1 ">
                    <p className="card-text text-muted small m-3">
                        <>
                            {
                                address?.company_name
                                    ? (<><strong>Company </strong> {address.company_name} <br /></>)
                                    : (<></>)

                            }
                        </>
                        <strong>Name </strong> {address.name} {address.last_name} <br />
                        <strong>Address </strong> {address.address_line_one} <br />
                        <strong>Address </strong> {address.address_line_two} <br />
                        <strong>City </strong> {address.city} <br />
                        <strong>Zip </strong> {address.zip_code} <br />
                        <>
                            {
                                address?.state
                                    ? (<><strong>State </strong> {address.state} <br /></>)
                                    : (<></>)

                            }
                        </>
                        <strong>Country </strong> {address.country}
                    </p>
                </MDBCardBody>
                {/*  EDIT ADDRESS BUTTONS  */}
                <MDBCardFooter className="d-flex flex-nowrap justify-content-end gap-2 p-2 ">
                    <MDBBtn
                        onClick={() => toggleEditDialog()}
                        className="btn-floating"
                        color='white'                    >
                        <MDBIcon icon="pen "></MDBIcon>
                    </MDBBtn>
                    <MDBBtn
                        onClick={() => toggleDeleteDialog()}
                        className="btn-floating"
                        color='white'
                    >
                        <MDBIcon icon="trash "></MDBIcon>
                    </MDBBtn>
                </MDBCardFooter>
            </MDBCard>
            {/* The Modals */}
            <DeleteAddressModal address={address} show={showDeleteDialog} setShow={setShowDeleteDialog} />
            <EditAddressModal address={address} show={showEditDialog} setShow={setShowEditDialog} />
        </>
    )
}


const AddressCardsView = ({ showHeader, title, onChange, required }) => {
    const [loading, setLoading] = useState(true)
    const [addresses, setAddresses] = useState([])
    const [showAddDialog, setShowAddDialog] = useState(false)
    const toggleAddDialog = () => setShowAddDialog(!showAddDialog)
    const [selectedAddress, setSelectedAddress] = useState('')
    const url = 'profile/addresses/'
    const api = useApiPrivate()
    

    const getAddresses = async () => {
        try {
            const response = await api.get(url)
            const _addresses = response?.data?.results
            setAddresses(_addresses)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }



    useEffect(() => {
        getAddresses()
    }, [showAddDialog])

    useEffect(()=>{
        if(!selectedAddress){
            return
        }
        onChange(selectedAddress)
    },[selectedAddress])


    return (
        <LoadingOrContent loading={loading}>

            {

                showHeader ? (<>
                    <div className="d-flex justify-content-between align-items-center " >
                        <h4 className="h6 mb-0 pb-0">{title}</h4>
                        <MDBBtn
                            color='link'
                            className="mb-0"
                            onClick={() => toggleAddDialog()}
                        ><MDBIcon fas icon="plus-circle" /> Add Address</MDBBtn>
                    </div>
                    <hr className="hr mt-1" />
                </>
                ) : (<br />)
            }
            {
                addresses.length
                    ? (
                        <MDBCardGroup className="gap-3">
                            {addresses.map((address, index) => (
                                <AddressCard
                                    key={index}
                                    address={address}
                                    selectable={showHeader}
                                    onChange={getAddresses}
                                    onSelect={(e)=>{setSelectedAddress(e.target.value)}}
                                />
                            ))
                            }
                        </MDBCardGroup>
                    )
                    : (
                        <div className="text-center"
                        > You do not have an address in your profile.
                            An address is only needed if you want to buy a domain from us or when you subscribe to a webhosting plan.
                            <br />
                       

                        </div>
                    )
            }
            <hr className="hr hr-blurry" />
            <MDBBtn
                onClick={() => toggleAddDialog()}
                color='outline-primary'
                hidden={showHeader}
            >
                <MDBIcon fas icon="plus-circle" /> Add Address
            </MDBBtn>
            <AddAddressModal show={showAddDialog} setShow={setShowAddDialog} />
        </LoadingOrContent>


    )
}

export default AddressCardsView