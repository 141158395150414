import React from 'react';
import { createRoot } from "react-dom/client";
// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
//import 'assets/themes/dark.css';




import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'features/Authentication';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


// import i18n (needs to be bundled ;))
import 'i18n/i18n';

const container = document.getElementById("root");
const root = createRoot(container);

// this is very primitive but works for now
const isDarkMode = () =>
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

const toggleStyle = () => {
  const css_link_tag = document.getElementById('theme-css-link')
  if(isDarkMode()){
    css_link_tag.setAttribute('href',`${window.location.origin.toString()}/dark.css` )
  }else{
    css_link_tag.setAttribute('href',`${window.location.origin.toString()}/default.css` )
  }
}

toggleStyle()

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/*' element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
