import axios from "api/axios";
import {useAuth} from "features/Authentication";
import useCookies from "hooks/useCookies";

export const useRefreshToken = () => {
    const {  setAuth } = useAuth()
    const { getCookie } = useCookies()

    const refresh = async () => {
        const response = await axios.post('/token/refresh/',JSON.stringify({
            refresh: getCookie('refresh') // we need to send this due to simpleJWT settings 
        }),
        {            
            headers: {'Content-Type': 'application/json'},           
            withCredentials: true,
            mode: 'same-origin'

        })
        setAuth(previousState => {
            return { ...previousState, accessToken: response.data.access, isAuthenticated: true }
        })
        return response.data.access
    }
    return refresh
}

