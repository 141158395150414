import { LoginForm, SocialLogin } from 'features/Authentication';
import { MDBContainer } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const LoginPage = () => {
    const { t } = useTranslation()

    return (
        <section className='overflow-hidden'>
            <MDBContainer className='slide-in gap-3 col-12 col-xl-6 border border-0 rounded-5 d-flex flex-column align-items-center' >
                <LoginForm />
                <SocialLogin title={t('authentication.headings.social-login')} />
                <div className='d-flex justify-content-center gap-3'>
                    <NavLink className="btn btn-link btn-sm" to='/recover/'>
                        {t('authentication.nav.account-recovery')}
                    </NavLink>
                    <NavLink className="btn btn-link btn-sm" to='/register/'>
                        {t('authentication.nav.create-account')}
                    </NavLink>
                </div>
            </MDBContainer>
        </section>
    )
}


