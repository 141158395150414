import { MDBIcon } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next"

export const WHSelectedPlanWidget = ({ plan }) => {
    const { t } = useTranslation()

    const getSoftwareDetails = () => {
        switch (plan?.install_software) {
            case 'WP':
                return ['wordpress', 'Wordpress']
            case 'JO':
                return ['joomla', 'Joomla']
            case 'DR':
                return ['drupal', 'Drupal' ]          
            default:
                return ['', '']
        }
    }
    const [softwareIcon, softwareName] = getSoftwareDetails()    

    return (plan &&
        <section className="mb-3">            
            <p className="h6"> {t('webhosting.components.SelectedPlanWidget.title')}</p>
            <hr className="hr" />
            <ul className="list-group list-unstyled" id="selectedPlanList">
                <li className="list-group-item p-2 text-center text-white bg-primary bg-gradient">
                    <h5><MDBIcon fab icon={softwareIcon} /> {plan?.name}</h5>
                </li>
               
                <li className="list-group-item">
                    <MDBIcon fas icon="database" /> {plan?.databases} {t('webhosting.plans.items.databases')}
                    </li>
                <li className="list-group-item">
                    <MDBIcon fas icon="hdd" /> {plan?.storage_gb} GB {t('webhosting.plans.items.storage')}
                </li>
                <li className="list-group-item">
                    <MDBIcon fas icon="mail-bulk" /> {plan?.email_accounts} Email Accounts
                </li>
                <>{plan.subdomains > 0 ? (
                    <li className="list-group-item">
                        <MDBIcon fas icon="globe" /> {plan?.subdomains} Subdomains
                    </li>
                ) : null}
                </>
                <>
                    {
                        plan?.install_software && (
                            <li className="list-group-item"><MDBIcon fab icon={softwareIcon} />  {softwareName} {t('webhosting.plans.items.instant')} </li>
                        ) 
                    }
                </>
                <li className="list-group-item p-3 text-end text-success fw-bold">
                    {t('webhosting.currency.symbol')} {plan?.plan_price_monthly} {t('webhosting.plans.monthly')}
                </li>
                
            </ul>
        </section>
    )
}