import axios from 'api/axios';
import { LoadingOrContent } from 'components/ui';
import useLocalStorage from 'hooks/useLocalStorage';
import { MDBBtn, MDBRow, MDBCol, MDBInput, MDBCheckbox, MDBTextArea, MDBValidation } from 'mdb-react-ui-kit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';


/* 
    Our Special inquiry contact form should perform a field and a captcha check and on success of both submit
    the inquiry to our api. The API will then handle the notifications to the admin and optional 
    email copies to the sender. 
    
    Additional safety measures:     
        Deactivate the field for the sender and do not allow to send multiple messages
        in one session.

        Implement a captcha that measures time from render to change and rejects if too fast

    TODO:
        Backend not ready yet
*/
export const WHContactForm = () => {
    const {t} = useTranslation()

    const [nameErr, setNameErr] = useState('')
    const [emailErr, setEmailErr] = useState('')
    const [companyErr, setCompanyErr] = useState('')
    const [phoneErr, setPhoneErr] = useState('')
    const [msgErr, setMsgErr] = useState('')
    const [nonFieldError, setNonFieldError] = useState('')

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [company, setCompany] = useState('')
    const [phone, setPhone] = useState('')
    const [msg, setMsg] = useState('')
    const [sendCopy, setSendCopy] = useState(false)
    // if we already received a message from this session, we will not allow to send the form again
    const [received, setReceived] = useLocalStorage('received') 

    const [loading, setLoading] = useState(false)

    const sendRequest = (e) => {
        e.preventDefault()
        setMsgErr('')
        setEmailErr('')
        !msg && setMsgErr('We need you to describe what you need')
        !email && setEmailErr('Without email we cannot quote you')

        const send = async () => {
            setLoading(true)
            try {
                const request = await axios.post('/web/special-inquiry/')
            } catch (error) {
                setNonFieldError(`Oops! Something went wrong. Error: ${error}`)
            } finally {
                setLoading(false)
                setReceived(Date.now())
            }
        }
        msg && email && send()
    }
    
    return (
        <LoadingOrContent loading={loading}>
            {
            !received || Date.now()-received > 300 ? // received was set or was set 300 ticks ago
            <MDBValidation onSubmit={sendRequest} >
                <MDBRow>
                    <MDBCol >
                        <MDBInput wrapperClass='mb-4' label='Full Name' value={name} onChange={(e) => { setName(e.target.value) }} />
                        {nameErr && <p className='text-danger small'>{nameErr}</p>}
                    </MDBCol>
                    <MDBCol >
                        <MDBInput wrapperClass='mb-4' type='text' label='Company Name' value={company} onChange={(e) => { setCompany(e.target.value) }} />
                        {companyErr && <p className='text-danger small'>{companyErr}</p>}
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <MDBInput wrapperClass={'mb-4'} label='Contact Phone' value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                        {phoneErr && <p className='text-danger small'>{phoneErr}</p>}
                    </MDBCol>
                    <MDBCol>
                        <MDBInput type='email' wrapperClass='mb-4' label='Email address' value={email} onChange={(e) => { setEmail(e.target.value) }} required />
                        {emailErr && <p className='text-danger small'>{emailErr}</p>}
                    </MDBCol>
                </MDBRow>
                {msgErr && <p className='text-danger small'>{msgErr}</p>}
                <MDBTextArea
                    wrapperClass='mb-4'
                    rows='4'
                    label='Message'
                    value={msg}
                    onChange={(e) => { setMsg(e.target.value) }}
                    required

                />
                <MDBCheckbox
                    wrapperClass='d-flex justify-content-center mb-4'
                    label='Send me a copy of this message'
                    checked={sendCopy}
                    onChange={(e) => { setSendCopy(e.target.checked) }}
                />

                <MDBBtn type='submit' className='mb-4' block>
                    Send request
                </MDBBtn>

            </MDBValidation>
            : <p> Danke. Wir werden uns in kuerze bei ihnen melden</p>}
        </LoadingOrContent>
    )
}
