import {useAuth} from "features/Authentication";
import { useLocation, Outlet, Navigate } from "react-router-dom";

export const PrivateRoute = () => {
    const {auth} = useAuth()
    const location = useLocation()    

    return (
        auth?.accessToken
            ? <Outlet />
            : <Navigate to='/login' state={{from: location}} replace />
    )
}

