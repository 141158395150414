/* 
    This is the Checkout Details Page

    If the user is signed in, they can select the domain, Plan and Addons and finally their desired billing address.
    By submitting this form the user will be redirected to the CheckoutPayment Page.

    If the user is not signed in, they can decide wether they want to continue with sign in or registration. 
    The user will be redirected to either of the pages and redirected to the checkout details page on success. 

    Todo:
        * This page needs some adjustment in regards to the session authentication.
        * Save the consent in profile -> implement in backend first.

    Later:
        * In the future the prices should be fetched from stripe according to the users location/language    


    MDBValidation:
        Does not seem to work with all of our custom components. We will customize it instead.
*/

import { MDBBtn, MDBIcon, MDBRow, MDBCol, MDBInputGroup, MDBTypography, MDBCheckbox, MDBValidationItem, MDBValidation } from 'mdb-react-ui-kit';
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
/* Our own imports */
import { LoadingOrContent } from 'components/ui';
import {useAuth, useApiPrivate} from "features/Authentication";
import { AddressCardsView } from 'features/Shop';


// import useCsrf from 'hooks/useCsrf'; we might need it again after deactivating session authentication in the backend
import { LoginButton, RegisterButton } from 'features/Authentication';
import { WHSelectedPlanWidget, WHOtherPlansWidget } from 'features/Webhosting';
import { WHPlanAddons, usePlans } from 'features/Webhosting';

import { useTranslation } from 'react-i18next';

const DOMAIN_REGEX = '^(((?!-))(xn--|_)?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9\-]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$'


export const WHCheckoutDetailsPage = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)
    const { auth } = useAuth()
    const location = useLocation()
    const navigate = useNavigate()

    /* the private api */
    const api = useApiPrivate()

    /* Plan related */
    const [plans, selectedPlan, otherPlans] = usePlans()

    useEffect(() => {
        setLoading(!plans.length)
    }, [plans])

    const [totalPrice, setTotalPrice] = useState(0.00)


    // Form Fields

    /* Plan and addons */
    const [selectedAddons, setSelectedAddons] = useState([])
    /* Billing Address related */
    const [selectedAddress, setSelectedAddress] = useState('')
    /*  Domain related */
    const [domain, setDomain] = useState('')

    /* this is for later when we are able to offer domains and acutally have a domain selection widget */
    // const [selectedDomain, setSelectedDomain] = useState([]) 

    /* consent fields */
    const [acceptedTnC, setAcceptedTnC] = useState(false)
    //const [marketingConsent, setMarketingConsent] = useState(false) // uncomment in case we want to collect marketing consent

    // Form Field Errors and Non Field Errors
    const [domainError, setDomainError] = useState('')
    const [addressError, setAddressError] = useState('')
    const [acceptedTnCError, setAcceptedTnCError] = useState('')
    const [nonFieldError, setNonFieldError] = useState('') // a list of error strings
 

    const validateForm = () => {
        setAcceptedTnCError('')
        setAddressError('')
        setDomainError('')
        if (!domain || !domain.match(DOMAIN_REGEX)) {
            setDomainError(t('webhosting.pages.CheckoutDetails.field-errors.domain'))
        }
        if (!selectedAddress) {
            // mark the fields and display the message
            setAddressError(t('webhosting.pages.CheckoutDetails.field-errors.address'))
        }
        if (!acceptedTnC) {
            setAcceptedTnCError(t('webhosting.pages.CheckoutDetails.field-errors.acceptedTnC'))
        }
        return Boolean(domain && domain.match(DOMAIN_REGEX) && acceptedTnC && selectedAddress)
    }

    const createSubscription = async (e) => {
        /* This function is called on form submit and will call the form validator
        
            If the form is valid, a subscription will be created in the backend
            If no error occured, the user will then be redirected to the CheckoutPaymentPage

        */
        // prevent default form action and page reload
        e.preventDefault()
        // validate form data first and stop if the form is not valid
        if (!validateForm()) {
            return
        }

        setLoading(true)
        // try to create subscription and redirect user to the payments page
        try {
            const _addons = selectedAddons.map(a => a.name) // Map the names of the selected addons to a new array
            const response = await api.post('/web/subscriptions/', JSON.stringify({
                "domain": domain,
                "webhosting_plan": selectedPlan.id,
                "billing_address": selectedAddress,
                "plan_addons": _addons // We expect an array with the names of the selected addons in the backend
            }))
            const subscription = response?.data
            if (response.status === '200' || '201') {
                navigate(`/webhosting/subscriptions/${subscription.id}/checkout`)
            }
        } catch (err) {
            // TODO: Improve Error handling and feedback            
            setNonFieldError(t('webhosting.pages.CheckoutDetails.non-field-errors.connection'))
        } finally {
            setLoading(false)
        }
    }

    const selectAddons = useCallback((addons) => {
        setSelectedAddons(addons)
    }, [])

    useEffect(() => {
        /* Maybe better to use useMemo for this */
        /* Update the total price */
        let sum = 0.00
        sum += Number(selectedPlan.plan_price_monthly)
        selectedAddons.length && selectedAddons.map((a) => (a.price && (sum += Number(a.price))))
        setTotalPrice(sum.toFixed(2))
    }, [selectedAddons])



    return (
        <section className='row p-0 m-0'>
            <h3 className="mb-4 ">
                <i className="fas fa-certificate" style={{ marginRight: '0.3rem' }}> </i>
                Subscribe
                <a className="link" id="titleLinkPlanName" style={{ marginLeft: '0.3rem' }}>
                    {selectedPlan?.name}
                </a>
            </h3>
            <LoadingOrContent loading={loading}>
                <div className='col-12 col-md-4'>
                    <WHSelectedPlanWidget plan={selectedPlan} />
                    <WHOtherPlansWidget plans={otherPlans} />
                </div>
                <MDBCol size='md-8'>
                    {
                        /* make sure the user is authenticated */
                        auth.accessToken ? (

                            <form onSubmit={createSubscription} noValidate>
                                {
                                    nonFieldError && <p className='note note-danger'>{nonFieldError}</p>
                                }
                                <MDBRow>
                                    <h6>Domain</h6>
                                    <hr className='hr' />

                                    <section className='m-3'>
                                        <p className='small   fw-bold'>
                                            If not with us, you should have your domain registered with a registrar, in order to actually reach your site or app.
                                        </p>
                                        <MDBInputGroup noWrap textBefore={<MDBIcon fas icon='globe' />} >
                                            <input type='text' className={domainError ? 'form-control is-invalid' : 'form-control'} placeholder='example.com' name='domain' value={domain} onChange={(e) => { setDomain(e.target.value) }} required />
                                        </MDBInputGroup>
                                        {
                                            domainError && <p className='text-danger small'>{domainError}</p>
                                        }

                                        <br />
                                        <MDBTypography listUnStyled className='mb-0 small text-muted text-uppercase'>
                                            <li className='mb-1 small'>
                                                <MDBIcon fas icon='exclamation-circle' className='me-2 text-secondary' /> You must be the owner or authorized administrator of the domain.
                                            </li>
                                            <li className='small'>
                                                <MDBIcon fas icon='exclamation-circle' className='me-2 text-secondary' /> You can find your IP addresse(s) and information about domain setup in your webhosting panel.
                                            </li>
                                        </MDBTypography>
                                    </section>
                                </MDBRow>
                                <MDBRow>
                                    <h6>{t('webhosting.pages.CheckoutDetails.addons-title')}</h6>
                                    <hr className='hr' />
                                    <section className='m-3' >
                                        <p className='small   fw-bold'>
                                            {t('webhosting.pages.CheckoutDetails.addons-introduction')}
                                        </p>

                                        <WHPlanAddons id='whPlanAddons' onChange={selectAddons} />

                                        <br />

                                        <div className='d-flex flex-row justify-content-between m-3'>
                                            <div><h5> {t('webhosting.plans.total-price')}:</h5></div>
                                            <div><h5>{totalPrice} {t('webhosting.currency.symbol')}</h5><p className='m-0 small text-end'>{t('webhosting.plans.monthly')}</p></div>
                                        </div>
                                        <hr />
                                        <hr />
                                        <br />

                                        <MDBTypography listUnStyled className='mb-0 small text-muted text-uppercase'>
                                            <li className='mb-1 small'>
                                                <MDBIcon fas icon='exclamation-circle' className='me-2 text-secondary' />
                                                {t('webhosting.plans.price-information')}
                                            </li>
                                            <li className='small'>
                                                <MDBIcon fas icon='exclamation-circle' className='me-2 text-secondary' />
                                                {t('webhosting.plans.remorse-policy')}
                                            </li>
                                        </MDBTypography>
                                    </section>
                                </MDBRow>
                                <hr className='hr hr-blurry' />
                                <br />
                                {
                                    addressError && <p className='text-danger small'>{addressError}</p>
                                }

                                <AddressCardsView title='Billing Address' showHeader={true} onChange={setSelectedAddress} required />

                                <MDBRow>
                                    <MDBCol size='1' className='d-flex align-items-center justify-content-center'>

                                        <MDBCheckbox id="consentChecBox" aria-label='consentChecBoxLabel'
                                            checked={acceptedTnC}
                                            onChange={() => { setAcceptedTnC(!acceptedTnC) }}
                                            required
                                        />
                                    </MDBCol>
                                    <MDBCol>
                                        <label id="consentCheckBoxLabel">
                                            {t('webhosting.pages.CheckoutDetails.i-accept')} <a href='#'>{t('webhosting.pages.CheckoutDetails.terms-and-conditions')}</a> {t('webhosting.pages.CheckoutDetails.i-confirm')} <a href='#'>{t('webhosting.pages.CheckoutDetails.privacy-policies')}</a>.
                                        </label>
                                        {
                                            acceptedTnCError && <p className='text-danger small'>{acceptedTnCError}</p>
                                        }
                                    </MDBCol>
                                </MDBRow>
                                <hr className='hr' />
                                <div className='d-flex justify-content-end'>
                                    {/* When this button is clicked, we will create a subscription in the webhosting apps backend
                                    At this point in time, no subscription is created in either plesk or stripe. 

                                    When the subscription was successfuly posted we will forward the user to our
                                    Stripe Checkout Page
                                */}
                                    <MDBBtn type='submit'>Checkout and pay</MDBBtn>
                                </div>
                            </form>
                        )
                            : (
                                <>
                                    <hr />
                                    <p>
                                        You need to sign in or create an account in order to signup for webhosting.
                                    </p>
                                    <p>
                                        If you already have one, sign in and return to this page.
                                    </p>
                                    <div className='d-flex justify-content-end gap-3'>
                                        <RegisterButton text='Continue with signup' redirect={`${location.pathname}?domain=${domain || ''}`} />
                                        <LoginButton text='Continue with Login' redirect={`${location.pathname}?domain=${domain || ''}`} />
                                    </div>
                                </>
                            ) // if not authenticated display the above Buttons to continue with login or register
                    }

                    <br />

                </MDBCol>
            </LoadingOrContent>
        </section >
    )
}

