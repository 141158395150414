import { NavLink } from "react-router-dom"
import { useParams ,useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
export const WHOtherPlansWidget = ({ plans }) => {
    const {t} = useTranslation()
    const location = useLocation()
    const { planId } = useParams()

    const getNavLink = (gotoPlan) => {
        const currentLocation = location.pathname
        const gotoLocation = currentLocation.replace(planId, gotoPlan)    
        return gotoLocation 
    }

    return (
        <section className="mb-3">
            <p className="fw-bold small"> {t('webhosting.components.OtherPlansWidget.title')}</p>
            <hr className="hr" />
            <ul className="list-group list-unstyled" id="otherPlansList">
                { /* Map Plans to this html */
                    plans.map((plan) => (
                        <li key={plan.id} className="list-group-item p-1">
                            <NavLink className="list-group-item border-0 small"
                                to={getNavLink(plan.id)}>
                                <p className="fw-bold small mb-1"> {plan.name} </p>
                                <p className="m-0 fw-normal small">
                                    <i className="fas fa-database small" style={{ marginLeft: '0.3rem' }}/>
                                     {plan.databases} {t('webhosting.plans.items.databases')}
                                    <i className="fas fa-hdd small" style={{ marginLeft: '0.3rem' }} />
                                     {plan.storage_gb} {t('webhosting.plans.items.storage')}
                                    <i className="fas fa-mail-bulk small" style={{ marginLeft: '0.3rem' }}/>
                                     {plan.email_accounts} {t('webhosting.plans.items.inboxes')}
                                </p>
                            </NavLink>
                        </li>
                    ))
                }
            </ul>
        </section >

    )
}