const Hero = ({ img, caption, title, red, green, blue, hue }) => {

    return (
        <div
            className='p-5 text-center bg-image mb-4'
            style={{ backgroundImage: `url(${img})`, height: '400px' }}
        >
            <div className='mask' style={{ backgroundColor: 'rgba(0, 40, 70, 0.6)' }}>
                <div className='d-flex justify-content-center align-items-center h-100'>
                    <div className='text-white'>
                        <h1 className='mb-3'>{title}</h1>
                        <h6 className='mb-3 p-2'>{caption}</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero