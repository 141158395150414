import axios from 'axios'

const baseUrl = 'https://api.codingcow.de/api' // for dev env it is 'https://dev.local:8000/api'

// Setup the headers to work with request.is_ajax() in Backend 
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.withCredentials= true
axios.defaults.headers = {    
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
}

export default axios.create({
    withCredentials: true,
    baseURL: baseUrl,
})



export const apiPublic = axios.create({
    baseURL: baseUrl
   
})

export const apiPrivate = axios.create({
    baseURL: baseUrl,
    headers:{'Content-Type':'application/json'},
    withCredentials: true
})
