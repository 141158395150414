import { apiPrivate } from "api/axios";
import { useEffect } from "react";
import {useAuth, useRefreshToken} from "features/Authentication";

export const useApiPrivate = () => {
    const refresh = useRefreshToken()
    const { auth } = useAuth()

    useEffect(() => {

        const requestIntercept = apiPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`
                }
                return config;
            }, (error) => Promise.reject(error) 
        )

        const responseIntercept = apiPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config
                // DOUBLE CHECK IF WE HAVE TO HANDLE ANY OTHER ERROR CODES HERE
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true
                    const newAccessToken = await refresh()
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
                    return apiPrivate(prevRequest)
                }
                return Promise.reject(error)
            }
        )
        /* Clean Up */
        return () => {
            apiPrivate.interceptors.request.eject(requestIntercept)
            apiPrivate.interceptors.response.eject(responseIntercept)
        }

}, [auth, refresh])


return apiPrivate
}

