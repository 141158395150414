import { MDBBtn, MDBContainer, MDBInput } from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';

/* rename the axios object globaly in order to distinguish multiple APIs easier */
import axios from 'api/axios';
/* Hooks */
import { useAuth } from "features/Authentication";
import useCsrf from 'hooks/useCsrf'; /* Required when acivating JWT Force CSRF for obtaining initial JWT */

/* Router */
import { Navigate, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { TalkingCowHead } from 'components/ui';
import { SocialLogin } from 'features/Authentication';
import useCookies from 'hooks/useCookies';

/* i18n */
import { useTranslation } from 'react-i18next';


/*
    This Module contains the components for the Login Page

    ToDo Refactor api requests to use hooks
*/

// MOVE TO SETTINGS
const AUTH_URL = '/token/' // MOVE TO SETTINGS

export const LoginForm = () => {
    const { t } = useTranslation()
    const { auth, setAuth, setPersist } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/'
    const [csrf] = useCsrf()
    const [user, setUser] = useState('')
    const [pwd, setPwd] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const { setCookie } = useCookies()

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(AUTH_URL,
                JSON.stringify({
                    username: user,
                    password: pwd
                }), {
                headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrf },
                withCredentials: true,
                mode: 'same-origin'
            })
            // Get the Token => Will change to response.data.access when implementing JWT
            const accessToken = response.data.access
            const refreshToken = response.data.refresh

            // Save the global auth state       
            setAuth({ accessToken: accessToken, isAuthenticated: true, username: user, pwd: pwd })
            // Change to use with "Remeber me" checkbox
            setPersist(true)
            // setCookie('refresh', refreshToken, 1800)
            setCookie('refresh', refreshToken, 1800)
            // Reset User and Password Fields
            setUser('')
            setPwd('')
            navigate(from, { replace: true })
        } catch (err) {
            // refactor into switch ?
            if (!err?.response) {
                setErrMsg(t('general.error.connection'))
            } else if (err.response?.status === 400) {
                setErrMsg(t('authentication.error.credentials'))
            } else if (err.response?.status === 401) {
                setErrMsg(t('authentication.error.credentials'))
            } else {
                setErrMsg(t('general.error.unnamed'))
            }
        }
    }

    return (
        <>
            {
                auth.isAuthenticated && auth.accessToken && <Navigate to={from} />
            }
            <TalkingCowHead
                title={t('authentication.headings.greeting')}
                msg={errMsg || t('authentication.description.login')}   // "Sign in to start today's adventure" 
                err={errMsg || undefined}
            />
            <form className='row gap-3 m-2 col-8 justify-content-center' onSubmit={handleSubmit}>
                <MDBInput id="id_username" name='username' label={t('authentication.login.field.label.username')} inputMode='text' type='text' onChange={(e) => setUser(e.target.value)} value={user} required></MDBInput>
                <MDBInput id="id_password1" name='password1' label={t('authentication.login.field.label.password1')} type='password' onChange={(e) => setPwd(e.target.value)} value={pwd} required></MDBInput>
                <MDBBtn className='btn-dark col-6' type='submit'>{t('authentication.btn.login')}</MDBBtn>
                <hr className='hr hr-blurry' />
            </form>
        </>
    )
}



