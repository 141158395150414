import { MDBBtn, MDBBtnGroup, MDBCol, MDBIcon, MDBRow  } from "mdb-react-ui-kit"
import { useEffect, useState } from "react"

import submarine from 'assets/img/clipart/yellow-submarine.png'
import { LoadingOrContent } from "components/ui"
import {useApiPrivate} from "features/Authentication"
import {PageClipart} from "components/ui"
import axios from "api/axios"

const WHSubscriptions = () => {
    const [loading, setLoading] = useState(true)
    const [subscriptions, setSubscriptions] = useState([])
    const [plans, setPlans] = useState([])
    const api = useApiPrivate()

    // refactor: Get Plans first get Subsc only when we have plans and update them before setting the subscriptions state

    useEffect(() => {

        const getPlans = async () => {
            try {
                const response = await axios.get('/web/plans/')
                const _plans = response.data.results
                setPlans(_plans)
            } catch (err) {
                console.log(err)
            }
        }
        getPlans()
    }, [])


    useEffect(() => {

        // This effect will pull the subscriptions and plans
        const getSubscriptions = async () => {
            try {
                if (!plans?.length) {
                    return
                }
                const subs = await api.get('/web/subscriptions/')
                const _subs = subs?.data?.results
                const newSubs = []
                // loop through the subsciption
                // get the plan details out of the plans array

                _subs.forEach((sub) => {
                    console.log('loop through _subs ' + sub.plan_internal_name)
                    console.log(' anf find the plan in ' + plans)
                    const [_plan] = plans.filter((plan) => { return sub.plan_internal_name === plan.plan_internal_name })
                    sub.plan = _plan
                    console.log(sub)

                    newSubs.push(sub)
                })

                setSubscriptions(newSubs)
            } catch (err) {
                console.log(err)

            } finally {
                setLoading(false)
            }
        }
        getSubscriptions()
        // Ths Effect will take the subscriptions array and add 
        // the plan details into the plan field of each subscription

    }, [plans])


    return (
        <>
            <h3> Your Webhosting Subscriptions</h3>
            <br />
            <LoadingOrContent loading={loading}>
                {!subscriptions ? (
                    <>
                        <PageClipart src={submarine} caption='Oops. We could not find your subs.' />
                        <section>
                            <p className="note note-danger text-start m-0"> You don't have a webhosting subscription.</p>
                        </section>
                        <br />
                        <section className="text-center">
                            <h5>
                                Get one today.
                            </h5>
                            <br />

                            <WebHostingPlansView />
                            <br />
                            <MDBBtn> Or learn More ...</MDBBtn>
                        </section>
                        <br />

                    </>
                ) : (
                    <section>
                        <h4>Active Subscriptions</h4>
                        <MDBRow className="p-3">
                            {subscriptions.map((sub) => (

                                !sub.active
                                    ? <></>
                                    : <SubscriptionListItem
                                        key={sub.id}
                                        plan={sub?.plan}
                                        domain={sub.domain}
                                        active={sub.active}
                                        subscribedDate={sub.subscribed_date}
                                        nextRenewalDate={sub.next_renewal_date}
                                        lastRenewalDate={sub.last_renewal_date}
                                    />
                            ))
                            }
                        </MDBRow>
                        <br />
                        <h4>Inactive Subscriptions</h4>
                        <MDBRow>
                            {subscriptions.map((sub) => (
                                !sub.active ? <SubscriptionListItem key={sub.id} plan={sub?.plan} domain={sub.domain} canceledDate={sub.canceled_date} /> : <></>
                            ))
                            }

                        </MDBRow>
                    </section>
                )

                }

            </LoadingOrContent>
        </>
    )
}

export default WHSubscriptions

// REDESIGN THE ListIem

export const SubscriptionListItem = ({ plan, domain, price, subscribedDate, nextRenewalDate, lastRenewalDate, active, canceledDate, className }) => {
    return (
        <section className='border rounded p-3' >          
            <h4>{domain} </h4>
            <MDBRow >
                <div className=" p-3 m-2"  >
                    {
                        active
                            ? (
                                <>

                                    <div className="d-flex flex-wrap gap-4 justify-content-center align-items-center">
                                        <section className=" d-flex justify-content-center align-items-center">

                                            <div className="web-thumbnail-container">
                                                <div className='web-thumbnail'>
                                                    <iframe scrolling='no' src={`http://${domain}/`} alt='not-available' />
                                                </div>
                                            </div>

                                        </section>
                                        <div className="d-flex justify-content-center">
                                            <MDBBtnGroup vertical>
                                                <MDBBtn color='link'>Go to page</MDBBtn>
                                                <MDBBtn color='link'>admin panel</MDBBtn>
                                                <MDBBtn color='link'>email</MDBBtn>
                                                <MDBBtn color='link'>Download Invoice</MDBBtn>
                                                <MDBBtn color='link'>Change Plan</MDBBtn>
                                                <MDBBtn color='link'>Cancel Subscription</MDBBtn>
                                            </MDBBtnGroup>
                                        </div>
                                        <div>
                                            <h6 className="text-uppercase text-muted">Subscription Details</h6>
                                            <div className="d-flex gap-3 flex-wrap" >
                                                <div> <b>Active Plan:</b>
                                                    <p className="small text-muted text-uppercase">{plan?.name} </p>

                                                    <b> Subscribed since:</b>
                                                    <p className="small text-muted text-uppercase">{subscribedDate} </p>
                                                </div>
                                                <div>
                                                    <b> Next Renewal:</b>
                                                    <p className="small text-muted text-uppercase">{nextRenewalDate} </p>

                                                    <b> Charge:</b>
                                                    <p className="small text-muted text-uppercase">{plan?.plan_price_monthly} $ <br />  all tax and fees included</p>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </>
                            )
                            : (<MDBCol> {canceledDate || 'Canceled Date'}</MDBCol>)
                    }

                </div>
            </MDBRow>


        </section>
    )
}



export const WHSSettingsPage = () => {
    return (
        <>
            <h3 className="m-2"> <MDBIcon icon='cog' /> Subscription Settings <a className="link">#codingcow.de</a></h3>
            <section className="m-2">
                <h4 className="h6 ">Hosting Plan</h4>
                <hr className="hr" />
                <table className="table table align-middle m-3">

                    <MDBRow>
                        <MDBCol>
                            Domain
                        </MDBCol>
                        <MDBCol colspan="2">
                            codingcow.de
                        </MDBCol>
                        <MDBCol>
                            <a className="btn text-white btn-primary btn-floating" href="#!" role="button"
                                title="Go to Site" data-mdb-toggle="tooltip" data-mdb-placement="top">
                                <i className="fas fa-globe-africa"></i>
                            </a>
                            <a className="btn text-white btn-primary btn-floating" href="#!" role="button"
                                title="Open Webmail" data-mdb-toggle="tooltip" data-mdb-placement="top">
                                <i className="fas fa-inbox"></i>
                            </a>

                            <a className="btn text-white btn-primary btn-floating" href="hosting_panel.htm" role="button"
                                title="Go to admin panel" data-mdb-toggle="tooltip" data-mdb-placement="top">
                                <i className="fas fa-user-gear"></i>
                            </a>
                        </MDBCol>
                    </MDBRow>

                    {/* PLAN COLUMN VARIANT ONE */}
                    <MDBRow>
                        <MDBCol>
                            Plan
                        </MDBCol>

                        <MDBCol>
                            <p className="fw-bold mb-1">Web L</p>
                            <p className="text-muted text-nowrap small mb-0 ">
                                <i className="small fas fa-database text-primary "> 5 </i>
                                <i className="small fas fa-hdd text-primary"> 2TB </i>
                                <i className="small fas fa-mail-bulk text-primary"> 50</i>
                                <i className="small fas fa-down-left-and-up-right-to-center text-primary"> 50TB</i>
                            </p>

                            <span className="badge badge-success rounded-pill d-inline fw-normal">Usage within Limits.</span>
                        </MDBCol>
                        <MDBCol>
                            <p className="fw-bold small text-nowrap mb-1">Active Since</p>
                            <p className="fw-normal small text-nowrap mb-0 ">DD-MM-YYYY</p>


                            <p className="fw-bold small text-nowrap mb-1">Next Renewal</p>
                            <p className="fw-normal small text-nowrap mb-0 ">DD-MM-YYYY</p>

                        </MDBCol>
                        <MDBCol>
                            <div className="d-flex gap-1">


                                <a className="btn text-white btn-primary btn-floating" href="#!" role="button" title="Bills" data-mdb-toggle="tooltip"
                                    data-mdb-placement="top">
                                    <i className="fas fa-file-text"></i>
                                </a>

                                <a className="btn text-white btn-primary btn-floating" href="#anchorBillingInfo" role="button" title="Payment & Billing Settings" data-mdb-toggle="tooltip"
                                    data-mdb-placement="top">
                                    <i className="fas fa-wallet"></i>
                                </a>

                                <div className="dropdown" title="Change Plan" data-mdb-toggle="tooltip"
                                    data-mdb-placement="top">

                                    <a className="btn dropdown-toggle text-white btn-primary btn-floating"
                                        id="changePlanMenuBtn" data-mdb-toggle="dropdown" aria-expanded="false" href="#!"
                                        role="button"><i className="fas fa-bars"></i></a>

                                    <ul className="dropdown-menu" aria-labelledby="changePlanMenuBtn">
                                        <li><a className="dropdown-item" href="#">Upgrade</a></li>
                                        <li><a className="dropdown-item" href="#">Downgrade</a></li>
                                        <li>
                                            <hr className="hr" />
                                        </li>
                                        <li><a className="dropdown-item" href="#">Cancel Plan</a></li>
                                    </ul>

                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>

                    {/* PLAN COLUMN VARIANT TWO 
            <MDBRow>
                <MDBCol>
                    Plan
                </MDBCol>

                <MDBCol>
                    <p className="fw-bold mb-1">Web L</p>
                    <p className="text-muted text-nowrap small mb-0 ">
                        <i className="small fas fa-database text-primary "> 5 </i>
                        <i className="small fas fa-hdd text-primary"> 2TB </i>
                        <i className="small fas fa-mail-bulk text-primary"> 50</i>
                        <i className="small fas fa-down-left-and-up-right-to-center text-primary"> 50TB</i>
                    </p>
                    <span className="badge badge-success rounded-pill d-inline fw-normal">Usage within Limits.</span>
                <MDBCol>
                    <p className="fw-bold small text-nowrap mb-1">Active Since</p>
                    <p className="fw-normal small text-nowrap mb-0 ">DD-MM-YYYY</p>


                    <p className="fw-bold small text-nowrap mb-1">Next Renewal</p>
                    <p className="fw-normal small text-nowrap mb-0 ">DD-MM-YYYY</p>

                </MDBCol>
                <MDBCol>
                    <div className="btn-group-vertical">
                        <button className="btn btn-outline-primary">Upgrade</button>
                        <button className="btn btn-outline-primary">Downgrade</button>
                        <button className="btn btn-outline-danger">Cancel</button>
                    </div>

                </MDBCol>
            </MDBRow>
            */}
                </table>
            </section>

            <section id="anchorBillingInfo" className="m-2">
                <h4 className="h6">Billing Information</h4>
                <hr className="hr" />

                <table className="table align-middle m-3">

                    <MDBRow>
                        <MDBCol>Billing Address</MDBCol>
                        <MDBCol colspan="2">
                            <p className="text-muted small">
                                <strong>Company</strong> _______ ______ <br />
                                <strong>Name</strong> _______ ______ <br />
                                <strong>Address</strong> _______ ______ <br />
                                <strong>City</strong> _______ ______ <br />
                                <strong>Zip</strong> _______ ______ <br />
                            </p>
                            <span className="badge badge-info rounded-pill d-inline fw-normal"><i
                                className="fas small fa-info-circle"></i> This address will appear on your
                                invoice.</span>
                        </MDBCol>
                        <MDBCol>
                            <button className="btn btn-link">Edit</button>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol> Payment Method</MDBCol>
                        <MDBCol colspan="2">
                            <p className="fw-bold small mb-1"> Provider </p>
                            <p className="fw-normal small mb-1"> PayPal </p>


                            <span className="badge badge-danger rounded-pill d-inline fw-normal text-danger"><i
                                className="fas fa-warning text-warning"> </i><b className="fw-bold"> ACTION NEEDED:</b> Please
                                update your payment method. <i className="fas fa-warning text-warning"> </i> </span>

                        </MDBCol>
                        <MDBCol>
                            <button className="btn btn-link ">Edit</button>
                        </MDBCol>
                    </MDBRow>
                </table>
            </section>
        </>
    )
}


export const WHSBillingPage = () => {
    return (
        <>
            <h3 className="mb-4"><i className="fas fa-file-invoice"></i> Your Invoices <a className="link">#[subscription_name]</a></h3>

            <section>
                <p className="h6 small"> Your Inbox</p>
                <hr className="hr" />

                <ul className="list-group list-unstyled">
                    <li className="list-group-item bg-light">
                        <MDBRow>
                            <MDBCol width="20%">
                                <p className="fw-bold small mb-1"> Invoice Date </p>
                            </MDBCol>
                            <MDBCol width="10%">
                                <p className="fw-bold small mb-1"> Status </p>
                            </MDBCol>
                            <MDBCol>
                                <p className="fw-bold small mb-1"> Invoice Description </p>
                            </MDBCol>
                        </MDBRow>


                    </li>

                    <li className="list-group-item p-0">
                        <a className="list-group-item border-0" href="#">

                            <MDBRow>
                                <MDBCol width="20%">
                                    <p className="fw-normal small mb-1"> 30/3/1854 </p>
                                </MDBCol>
                                <MDBCol width="10%">
                                    <p className="fw-normal small mb-1"> Payed </p>
                                </MDBCol>
                                <MDBCol>
                                    <p className="fw-normal small mb-1"> #Invoice for your Subscription Plan "web l" </p>
                                </MDBCol>
                            </MDBRow>


                        </a>
                    </li>
                    <li className="list-group-item p-0">
                        <a className="list-group-item border-0" href="#">
                            <table width="100%">
                                <MDBRow>
                                    <MDBCol width="20%">
                                        <p className="fw-normal small mb-1"> 20/04/2018 </p>
                                    </MDBCol>
                                    <MDBCol width="10%">
                                        <p className="fw-normal small mb-1"> Payed </p>
                                    </MDBCol>
                                    <MDBCol>
                                        <p className="fw-normal small mb-1"> #Invoice for your Subscription Plan "web l" </p>
                                    </MDBCol>
                                </MDBRow>
                            </table>

                        </a>
                    </li>
                    <li className="list-group-item p-0">
                        <a className="list-group-item border-0" href="#">
                            <table width="100%">
                                <MDBRow>
                                    <MDBCol width="20%">
                                        <p className="fw-normal small mb-1"> 22/04/1784 </p>
                                    </MDBCol>
                                    <MDBCol width="10%">
                                        <p className="fw-normal small mb-1"> Pending </p>
                                    </MDBCol>
                                    <MDBCol>
                                        <p className="fw-normal small mb-1"> #Invoice for your Subscription Plan "web l" </p>
                                    </MDBCol>
                                </MDBRow>
                            </table>

                        </a>
                    </li>
                    <li className="list-group-item text-bg-light ">
                        <nav aria-label="...">
                            <ul className="pagination  d-flex justify-content-center">
                                <li className="page-item ">
                                    <a className="page-link">Previous</a>
                                </li>
                                <li className="page-item"><a className="page-link" href="#">1</a></li>
                                <li className="page-item active" aria-current="page">
                                    <a className="page-link" href="#">2 <span className="visually-hidden">(current)</span></a>
                                </li>
                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                <li className="page-item">
                                    <a className="page-link" href="#">Next</a>
                                </li>
                            </ul>
                        </nav>

                    </li>
                </ul>
            </section>
        </>
    )

}