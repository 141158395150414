import { Outlet, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

import { useAuth, useRefreshToken } from "features/Authentication"
import { LoadingOrContent } from 'components/ui'

export const LoginPersistance = () => {
    const [loading, setLoading] = useState(true)
    const refresh = useRefreshToken()
    const { auth, persist } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        let isMounted = true

        const verifyRefreshToken = async () => {
            try {
                await refresh()
            } catch (err) {
                console.error(`Error while refresh: ${err}`)
                // This might be a good place to clear the previous auth state and cookies
                // or maybe not user would have to sign in all over 
                /// again if they have reoccuring network issues        
            } finally {
                isMounted && setLoading(false) // this sets loading to false only if the component is mounted
            }
        }
        !auth?.accessToken && persist ? verifyRefreshToken() : setLoading(false)
        return () => isMounted = false
    }, [])


    return (
        <>
            {persist
                ? <LoadingOrContent loading={loading} >
                    <Outlet />
                </LoadingOrContent>
                : <Outlet />
            }

        </>
    )

}

