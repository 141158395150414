import React from "react";
import { MDBRow, MDBCol, MDBBadge, MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import meadow from 'assets/img/meadow.webp'
import meadow2 from 'assets/img/meadow_at_sea.webp'

const Home = () => {
    return (
        <div className='fade-in'>
            <div
                className='p-5 text-center bg-image'
                style={{ backgroundImage: `url(${meadow})`, height: '400px' }}
            >
                <div className='mask' style={{ backgroundColor: 'rgba(0, 30, 0, 0.6)' }}>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-white'>
                            <h1 className='mb-3'>Welcome {/* if user is signed in, print out name here*/}to the ultimate coding meadow.</h1>
                            <h6 className='mb-3'>We are a premier provider of webhosting, email and colaborative workspaces.<br />
                                Our goal is to provide the best possible services for our customers, while ensuring the safety of their data at all times.
                            </h6>

                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />

            <section className="m-0 p-3 rounded border">
                <h4 className="text-center ">
                    Fast <MDBIcon icon='fighter-jet' /> Secure <MDBIcon icon='shield-alt' />   Reliable <MDBIcon icon='anchor' /></h4>
                <br />

                <p>

                    We&apos;ve designed our services to be easy to use, secure and reliable, so you can focus on developing your website and applications
                    without worrying about technical issues.
                </p><p>
                    Our webhosting services include unlimited storage¹, bandwidth², and uptime³,
                    as well as advanced features such as SSL certificates and daily backups.
                </p><p>
                    We also provide email services with unlimited storage and anti-spam protection.
                    Our python libraries are regularly updated and are fully compatible with the latest versions of Python.
                </p><p>
                    Finally, our free to play Minecraft server is always up and running, so you can enjoy the popular game with your friends!
                    Sign up now and get the most out of our services!

                </p>
            </section>
            <br />

            <div
                className='p-5 text-center bg-image mb-4'
                style={{ backgroundImage: `url(${meadow2})`, height: '400px' }}
            >
                <div className='mask' style={{ backgroundColor: 'rgba(0, 20, 80, 0.6)' }}>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-white'>
                            <h1 className='mb-3'>Profit from a unique combination of services.</h1>
                            <h6 className='mb-3'>
                                We develope our services with privacy and safety as number one priority.
                            </h6>

                        </div>
                    </div>
                </div>
            </div>
            <MDBRow className="gap-2 flex-wrap mb-4">
                <MDBCol size='md-6' >
                    <section className="m-0 p-3 rounded border">
                        <h4> Webhosting</h4>
                        <p>
                            <b>Good Webhosting</b> can be a pain to find. There are just so many options out there, from microblogs to rather complicated cloud implementations.
                            Compare the market and finding the right fit, often is a complex task in itself.</p>
                        <p>
                            But your website might need some more than &apos;just&apos; a good webhosting provider. A reliable Tool Set that does not require two weeks of staff training,
                            and a Colaboration Platform to work with your team are just as important, when you want to be efficient and successful.
                        </p>
                        <p>
                            Wouldn&apos;t it be cool to have all in one space and not having an absurd amount of different subscriptions to keep an eye on?
                        </p>
                        <p>
                            Well, look no further, you have just arrived at the juciest meadow you will encounter on your journey.
                            You will pay only for your webhosting and email plan, everything else comes free as the daisy in the hay stack.
                        </p>

                    </section>

                    <br />
                    <section className="m-0 p-3 rounded border">
                        <h4>Domains <MDBBadge>New</MDBBadge></h4>
                        <p>
                            <b>Don&apos;t have a domain yet? </b> Secure your wish domain today. If you book a Webhosting Plan, you may even get it for free³.
                        </p>
                        <p>
                            Manage your domain portfolio the easy way. We provide a setup wizard, that will guide even your tik tok addicted intern to a successful and secure setup.
                        </p>
                    </section>
                </MDBCol>
                <MDBCol>

                    <section className="m-0 p-3 rounded border">
                        <h4> Colaboration <MDBBadge>New</MDBBadge></h4>
                        <p>
                            Many big projects evolve out of communities and the other way around. Wonder how? Documentation, Organsiation and Communitaction are the keys to succes.
                        </p>
                        <p>
                            So we came up with a new way of bringing all important data together while protecting the privacy of each and every individual in the herd.
                            <i> (They do not even know, who wrote this text you&apos;re reading right now.)</i>
                        </p>
                        <p>
                            Documents, Design, Code and Notes, Groups, Mails and Chats are all linked into one mind map, giving your team a hive mind they can rely on.
                        </p>
                        <p>
                            Each individual can decide, which files and data they want to share with the herd.
                        </p>
                        <p>
                            You can also find or create completely anonymous groups, where no one knows the others,
                            while all are working efficiently towards their common goal.
                        </p>
                    </section>
                    <br />


                    <section className="m-0 p-3 rounded border">
                        <h4> Charity and education</h4>
                        <p>
                            As public schools and charities alike struggle with funding in the digital sector, we provide a 90% discount to all charities and public schools.
                            We also offer a special programm, where your public school can apply for free services.
                        </p><p>
                            Please be aware that some countries have special requriements, we are not able to meet yet. We are working towards compatibilty with all thinkable legal constelations in that regard. Please do not hesitate to contact us if you have concerns in that regard.
                        </p>
                    </section>
                </MDBCol>

            </MDBRow>
            <div
                className='p-5 text-center bg-image mb-4'
                style={{ backgroundImage: `url(${meadow})`, height: '400px' }}
            >
                <div className='mask' style={{ backgroundColor: 'rgba(0, 20, 20, 0.6)' }}>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-white'>
                            <h1 className='mb-3'>Find out more about what drives us.</h1>
                            <h6 className='mb-3'>
                                We have a clear philosophie and follow our mission to make the world a better (work) place.
                            </h6>

                        </div>
                    </div>
                </div>
            </div>

            <section className="m-0 p-3 rounded border">
                <h4> Our Philosophie</h4>

                <p>
                    We aim to provide the tools needed to democratice the workplace. Selforganized learning and working proofed to be non less efficient,
                    than strictly organized cubicle farms. When it comes to creativity and inovation, the concept may even be superior to traditonal ways of organization.
                </p>
                <p>
                    The simple idea is to give individuals the room, security and time they need, to develop new ways and skills to organize their workload and solve new problems.
                </p>
                <p> While we can and will not interfere with your business and employment strategies, we provide said toolset and are happy to share our expirences with you.</p>
                <p> See our blog to learn more about the future of creative work.</p>

                <p> We are aware of the fact that some companys like to turn their colaboration platforms into data hungry analytics monsters,
                    that give employers more control over their employees as ever before.</p>
                <p> If you are looking for one of these solutions, we wish you the best on your journey but will not be able to host or accompany you.

                </p>

            </section>

            <br />

            <hr className="hr " />
            <section className="small">
                <p className="small text-uppercase text-muted p-3">
                    ¹With Unlimited Web Package only. See each plans details to learn more about different storage options.
                    <br />
                    ²Bandwith describes the traffic your website receives.
                    <br />
                    ³We guarantee an uptime of 99% on dedicated web servers and 98% on shared webhosting plans.
                    <br />
                    <br />
                    All offers are subject to change. Terms and conditions apply.
                </p>
            </section>
        </div>

    )
}

export default Home