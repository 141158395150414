/* This is the Webhosting Page build from the components/Webshoting modules components */

import React from "react";
import { WHPlansList, WHContactForm } from "features/Webhosting";
import { useTranslation } from "react-i18next";

import datacenter from "assets/img/pexels-brett-sayles-5480781.jpg"
import contact from "assets/img/pexels-chepté-cormani-1416530.jpg"
export const WHLandingPage = () => {
    const { t } = useTranslation()
    return (
        <div className='fade-in'>

            <div
                className='p-5 text-center bg-image mb-4'
                style={{ backgroundImage: `url(${datacenter})`, height: '400px' }}
            >
                <div className='mask' style={{ backgroundColor: 'rgba(0, 20, 80, 0.6)' }}>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-white'>
                            <h1 className='mb-3'>
                                {/*Profit from a unique combination of services.*/}
                                {t('webhosting.pages.LandingPage.hero-title')}

                                </h1>
                            <h6 className='mb-3'>
                                {/*We develope our services with privacy and safety as number one priority.*/}
                                {t('webhosting.pages.LandingPage.hero-sub-title')}

                            </h6>

                        </div>
                    </div>
                </div>
            </div>
            <section className="border rounded p-3">
                <h3>
                    {/* Get the right plan for your project now! */}
                    {t('webhosting.pages.LandingPage.plans-title')}
                </h3>

                <p>
                    {/*Are you looking for and easy and affordable way to take your website to the next level? Look no further! Our monthly WordPress subscription for just £1 per month is the perfect way to enhance your website with the latest features and plugins. With our subscription, you'll have access to a library of over 50 plugins and themes, giving you the freedom to customize your website to exactly how you want it. Plus, you can rest easy knowing that our professional team of experts are always on hand to provide support and guidance whenever you need it. So what are you waiting for? Sign up today and make your website stand out from the crowd!*/}
                    {t('webhosting.pages.LandingPage.plans-introduction')}
                </p>
                <br />
            </section>
            <br />

            <WHPlansList />

            <hr className='hr' />
            <br />
            <div
                className='p-5 text-center bg-image mb-4'
                style={{ backgroundImage: `url(${contact})`, height: '400px' }}
            >
                <div className='mask' style={{ backgroundColor: 'rgba(40, 0, 80, 0.6)' }}>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-white'>
                            <h1 className='mb-3'>
                                {/* Contact us to request a custom plan.*/}
                                {t('webhosting.pages.LandingPage.contact-us-hero-title')}
                            </h1>
                            <h6 className='mb-3'>
                               {/* We do our best to offer flexible plans for every need. We know that sometimes it just is not enough.*/}
                               {t('webhosting.pages.LandingPage.contact-us-hero-sub-title')}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <section className="border rounded p-3">
                <h3 className="text-center"> 
                    {/*Special Inquiries*/}
                    {t('webhosting.pages.LandingPage.contact-us-form-title')}
                </h3 >
                <p>
                    {/*Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.*/}
                    {t('webhosting.pages.LandingPage.contact-us-form-introduction')}

                </p>
                <WHContactForm />




            </section>

        </div>

    )

}

