
import { Hero } from "components/ui"
import { MDBCardGroup } from "mdb-react-ui-kit";
import { useCategory, BlogArticleCard, BlogCategoriesList } from "features/Blog"
import { useTranslation } from "react-i18next";
import landscape from "assets/img/img_20191103_131018.jpg"

export const BlogStartPage = () => {
    const [categoryDetails, articles] = useCategory('latest')
    const {t} = useTranslation()
    return (
        <div className='fade-in'>
            <Hero img={landscape} title="Welcome to our cowtastic blog!" caption='This is the place to share our expirences with you and keep you updated on our latest products and services.' />
            <p className="note note-warning text-danger">
                If you are looking for support or a documentation
                for a product, check our Help Center.
            </p>

            <section className="border rounded p-3">
                <h3>What you can expect to find here</h3>
                <p>
                    We try to publish as frequent as possible, while we are also working to improve codingcow for you everyday.
                    Creating, Improving and Maintaining a Web Service like this, can be a tedious task with many little problems
                    to overcome.
                </p>
                <p>The topics handled in this blog revolve mainly around the following topics:</p>

                <h6>Web Dev</h6>
                <ul>
                    <li> How are web services build?</li>
                    <li> What Framework is the right one for me?</li>
                    <li> Which programming language should I learn?</li>
                    <li> How does a database work?                    </li>
                </ul>
                <p>We will share our expierence in this blog, in the hope that it proofs useful to you on your quest to answer questions like the above.
                    Start reading about these topics now in our Web Dev Categorie.
                </p>
                <br />

                <h6>Cyber Security</h6>
                <ul>
                    <li> What is a hacker?</li>
                    <li> How to protect your online presence and ressources?</li>
                    <li> What is ethical hacking?</li>
                    <li> How to ensure my code is safe?</li>
                </ul>
                <p>These questions and various other topics as well as writeups for TryHackMe Rooms and HackTheBox machines can be found in the cyber security category.</p>
                <br />
            </section>
            <br />
            <section >
                <h4 className="h4 ">{categoryDetails?.name}</h4>

                <p>{categoryDetails?.short_description}</p>
                <hr className="hr " />

                <div className="d-flex flex-wrap">
                    <div className="col-12 col-md-9 col-xl-10">
                        <MDBCardGroup className='gap-2 justify-content-start' >
                            {articles.map(article => (
                                <BlogArticleCard
                                    key={article.id}
                                    id={article.id}
                                    title={article.title}
                                    description={article.description}
                                    author={article.author}
                                    publishedOnDate={article.published_date}
                                    img={article.image}
                                />
                            ))}
                        </MDBCardGroup>
                    </div>
                    <div className="col-12 col-md-3 col-xl-2">
                        <BlogCategoriesList />
                    </div>
                </div>
            </section>
        </div>
    )
}