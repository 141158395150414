
import { MDBRow } from "mdb-react-ui-kit"

const PageClipart = ({src, alt, caption}) => {

    return (
        <MDBRow className="d-flex justify-content-center mt-5">
            <img src={src} alt={alt} className='clipart col-3 p-1 m-1' />
            <p className="small fw-bold text-uppercase text-center text-muted">{caption}</p>
        </MDBRow>

    )
}

export default PageClipart
