import { MDBBtn } from "mdb-react-ui-kit"
import {  useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"


export const LoginButton = ({ redirect, color}) =>{
    const { t } = useTranslation()
    const navigate = useNavigate() 
    return(
        <>
        { redirect
            ? ( <MDBBtn color={color} onClick={()=>{navigate(`/login?onSuccess=${redirect}`)}} > { t('authentication.btn.login-redirect')} </MDBBtn>) 
            : (  <MDBBtn color={color} onClick={()=>{navigate(`/login`)}} > { t('authentication.btn.login')} </MDBBtn>)}       
        </>
    )
}