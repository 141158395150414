import { MDBBtn } from "mdb-react-ui-kit"
import {  useNavigate } from "react-router-dom"

export const RegisterButton = ({text, redirect, color}) =>{
    const navigate = useNavigate() 
    return(
        <>
        { redirect
            ? ( <MDBBtn color={color} onClick={()=>{navigate(`/register?onSuccess=${redirect}`)}} > {text} </MDBBtn>) 
            : (  <MDBBtn color={color} onClick={()=>{navigate(`/register`)}} > {text} </MDBBtn>)}       
        </>
    )
}