import { BlogArticle, BlogCategoriesList, useArticle } from "features/Blog"
import { useParams } from "react-router-dom"

export const BlogArticlePage = () => {
    const { articleId } = useParams()
    //  target for the routes /blog/article/:id
 
    return (
        <>
            <section className="row gap-5">
                <BlogArticle articleId={articleId} />                                    
                <hr className="hr hr-blurry" />
                <BlogCategoriesList />
            </section>
        </>
    )
}




