/*
 The Register Page 

 */

import { MDBBtn, MDBCheckbox, MDBInput, MDBValidationItem } from 'mdb-react-ui-kit'
import { TalkingCowHead } from 'components/ui'
import { useAuth, PATTERN_EMAIL, PATTERN_PASSWORD, PATTERN_USERNAME } from 'features/Authentication'
import useCsrf from 'hooks/useCsrf'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import axios from 'api/axios'
import useCookies from 'hooks/useCookies'
import { useTranslation } from 'react-i18next'

const SIGNUP_URL = '/auth/registration/'

/* This does not reflect the backends validation 
   We might still get errors from the backend. 

   The remaining cases are:  
        - Username exists
        - Email exists
        - Password to close to username ! Could be handled here as well
        - additional Password not safe (backend checks against common passwordlists)
 
    DONE 
        - Implement Validation Errors to be displayed at the field
        - check if the classes for severside handling might be better suited for 
          this component. POSITIVE
        - mark the passwordMatch field with error 
        
    There is a bug with the Password Validation that updated it a char to late
    so for now we just adjusted the value in the regex
 
 
    TODO: 
       REFACTOR TO USE useReg hook
       Complete i18n ( errMsg from server need translations )
       Add redirect handling from location
       Fix Password check and Password match validation errors
 
*/

export const RegisterForm = () => {
    const { auth, setAuth, setPersist } = useAuth()
    const [csrf] = useCsrf()
    const { t } = useTranslation()

    const [email, setEmail] = useState('')
    const [emailOk, setEmailOk] = useState(false)
    const [emailErrMsg, setEmailErrMsg] = useState(t('authentication.register.field.error.email'))


    const [username, setUsername] = useState('')
    const [usernameOk, setUsernameOk] = useState(false)
    const [usernameErrMsg, setUsernameErrMsg] = useState(t('authentication.register.field.error.username'))

    const [password, setPassword] = useState('')
    const [passwordOk, setPasswordOk] = useState(false)
    const [passwordErrMsg, setPasswordErrMsg] = useState(t('authentication.register.field.error.password1'))


    const [passwordMatch, setPasswordMatch] = useState('')
    const [passwordMatchOk, setPasswordMatchOk] = useState(false)
    const [passwordMatchErrMsg, setPasswordMatchErrMsg] = useState(t('authentication.register.field.error.password2'))


    const [acceptTerms, setAcceptTerms] = useState(false)
    const [acceptErrMsg, setAcceptErrMsg] = useState('')

    const [formOk, setFormOk] = useState(false)

    const [subscribeNews, setSubscribeNews] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/'

    const [errMsg, setErrMsg] = useState('')

    const { setCookie } = useCookies()

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (formOk) {
            try {
                const response = await axios.post(SIGNUP_URL,
                    JSON.stringify({
                        username: username,
                        email: email,
                        password1: password,
                        password2: passwordMatch
                    }), {
                    headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrf },
                    withCredentials: true,
                    mode: 'same-origin'
                })
                // Get the Token 
                const accessToken = response.data.access_token
                const refreshToken = response.data.refresh_token
                setAuth({ accessToken: accessToken, isAuthenticated: true, username: username })
                setPersist(true)
                // setCookie('refresh', refreshToken, 1800)
                setCookie('refresh', refreshToken, 1800)
                // Reset User and Password Fields            
                navigate(from, { replace: true })
            } catch (err) {
                alert(err)
                // refactor into switch and use custom messages or implement i18 
                if (!err?.response) {
                    setErrMsg(t('authentication.error.connection'))
                } else if (err.response?.data?.non_field_errors) {
                    setErrMsg(err.response?.data?.non_field_errors)
                }

                if (err.response?.data?.username) {
                    setUsernameErrMsg(err.response?.data?.username[0])
                    setUsername('>.<')
                }

                if (err.response?.data?.password1) {
                    setPasswordErrMsg(err.response?.data?.password1[0])
                    setPassword('()>.<()')
                    setPasswordMatch('')
                }

                if (err.response?.data?.email) {
                    setEmailErrMsg(err.response?.data?.email[0])
                    setEmail('>.<')
                }

            }
        }
    }

    /* Form Checks  */

    useEffect(() => {
        const isOk = PATTERN_EMAIL.test(email)
        setEmailOk(isOk)
    }, [email])

    useEffect(() => {
        const isOk = PATTERN_USERNAME.test(username)
        setUsernameOk(isOk)
    }, [username])

    useEffect(() => {
        const isOk = PATTERN_PASSWORD.test(password)
        setPasswordOk(isOk)
        setPasswordMatchOk(password === passwordMatch)
    }, [password, passwordMatch])

    useEffect(() => {
        const ok = usernameOk && passwordOk && passwordMatchOk && emailOk && acceptTerms
        setFormOk(ok)
    }, [usernameOk, passwordOk, passwordMatchOk, emailOk, acceptTerms])

    return (
        <>
            {
                auth.isAuthenticated && auth.accessToken && <Navigate to={from} />
            }
            <TalkingCowHead
                title={t('authentication.headings.register')}
                msg={errMsg || t('authentication.description.register')}   // "Sign in to start today's adventure" 
                err={errMsg || undefined}
            />
            <form className='row gap-3 col-12 col-md-8 justify-content-center' onSubmit={handleSubmit} >
                <MDBValidationItem
                    feedback={emailErrMsg}
                    invalid
                    className='p-0 m-0'
                >
                    <MDBInput
                        type='email'
                        name='email'
                        label={t('authentication.register.field.label.email')}
                        className={email ? emailOk ? 'form-control is-valid active' : 'form-control is-invalid active' : 'form-control'}
                        id='id_email'
                        value={email} onChange={(e) => { setEmail(e.target.value) }}
                        required
                    />
                    {/*Account Information 
                                        <h4 className='h6 text-secondary text-center'>Account Information</h4>
                                        */}

                </MDBValidationItem>
                <MDBValidationItem
                    className='m-0 p-0'
                    feedback={usernameErrMsg}
                    invalid
                >
                    <MDBInput
                        type='text'
                        className={username ? usernameOk ? 'form-control is-valid active' : 'form-control is-invalid active' : 'form-control'}
                        name='username'
                        label={t('authentication.register.field.label.username')}
                        id='id_username'
                        aria-describedby='usernameGroupPrepend'
                        value={username} onChange={(e) => { setUsername(e.target.value) }}
                        required
                    />
                </MDBValidationItem>

                <MDBValidationItem className='m-0 p-0'
                    feedback={passwordErrMsg}
                    invalid
                >
                    <MDBInput
                        type='password'
                        className={password ? passwordOk ? 'form-control is-valid active' : 'form-control is-invalid active' : 'form-control'}
                        name='password1'
                        label={t('authentication.register.field.label.password1')}
                        id='id_password1'
                        autoComplete='new-password'
                        aria-autocomplete='list'
                        value={password} onChange={(e) => { setPassword(e.target.value) }}

                        required
                    />
                </MDBValidationItem>

                <MDBValidationItem className='m-0 p-0'
                    feedback={passwordMatchErrMsg}
                    invalid
                >
                    <MDBInput
                        type='password'
                        className={passwordMatch ? passwordMatchOk ? 'form-control is-valid active' : 'form-control is-invalid active' : 'form-control'}
                        name='password2'
                        label={t('authentication.register.field.label.password2')}
                        id='id_password2'
                        autoComplete='new-password'
                        value={passwordMatch} onChange={(e) => { setPasswordMatch(e.target.value) }}

                        required
                    />
                </MDBValidationItem>

                <MDBValidationItem className='m-0 p-0' >
                    {/* Terms and privacy */}
                    <MDBCheckbox
                        label={t('authentication.register.field.label.consent')}
                        id='id_acceptTermsCheckBox'
                        value={acceptTerms}
                        onChange={(e) => { setAcceptTerms(e.target.value) }}
                        required
                    />
                </MDBValidationItem>

                <MDBValidationItem className='m-0 p-0'>
                    {/* Sign Up for newsletter => not yet implemented in backend */}
                    <MDBCheckbox
                        id='id_subscribeCheckBox'
                        label={t('authentication.register.field.label.contact-consent')}
                        value={subscribeNews}
                        onChange={(e) => { setSubscribeNews(e.target.value) }}
                    />
                </MDBValidationItem>

                <MDBBtn className='btn-primary col-6' type='submit'>
                    {t('authentication.btn.register')}
                </MDBBtn>
                <hr className='hr hr-blurry' />
            </form>
        </>
    )
}

