import { useState, useEffect } from "react"

import {useAuth, useApiPrivate} from "features/Authentication"
const useUserProfile = () => {
    const [userProfile, setUserProfile] =useState([])
    const {persist} = useAuth()

    const api = useApiPrivate()

    const updateProfile = (data) =>{
        /* lets get this one ready and export it as setter for profile */
        const requestUpdate = async ()=>{
            try{
                const response = await api.put('/profile/', JSON.stringify(data))                
                
                if (response.status === 200) {
                  setUserProfile(data)
                }

            }catch(error){

            }

        }
        requestUpdate()        
    }

    useEffect(() => {
        const getProfile = async () => {
            try {
                const response = await api.get('/profile/')
                const _profile = response?.data?.results
                setUserProfile(_profile[0])
            } catch (err) {
                console.log(err)
            }
        }
        getProfile()
    }, [persist])

    return(
        userProfile
    )

}

export default useUserProfile