import logo from 'assets/img/logo-transparent.webp';
const TalkingCowHead = ({ title, msg, err, children }) => {

    return (
        <div className='d-flex cow-spawn'>
            <div className='col-2 d-flex align-items-center flex-wrap gap-0'>
                <img className='cow-talking ' src={logo} style={{ width: '100%', height: 'auto' }} />
            </div>
            <div className='speech-bubble border-secondary shadow-4 col-8 m-3 text-center'>
                <p className='h4 text-uppercase m-3 mb-0 pb-0'> {title}</p>
                <>
                    {
                        err ? (<p className='note note-danger'>{err}</p>)
                            : (<p className='text-muted text-uppercase small m-3'> {msg} </p>)
                    }
                </>
                <div className='m-3'>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default TalkingCowHead

