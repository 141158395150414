import { useEffect, useState } from "react"
import axios from "api/axios"
const useCsrf = () => {
    const [ csrf, setCsrf ] = useState('')
    useEffect(() => {
        const getToken = async () => {
            const _token = await axios.get('/csrf-token')
            setCsrf(_token?.data?.csrfToken)
        }
        getToken()
    },[]
    )
    return( [ csrf ] )
}

export default useCsrf