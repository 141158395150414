import { MDBDropdown, MDBDropdownMenu, MDBDropdownItem, MDBDropdownToggle, MDBIcon, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import React from 'react';
import { NavLink } from 'react-router-dom';
import {useAuth, useLogout} from "features/Authentication";
import useAvatar from 'hooks/apiHooks/useAvatar';
import { useTranslation } from 'react-i18next';


/* This Module contains all components to display the accounts menu in the NavBar
    +---------------------------------------+
    | L | links                   [ACC_MENU]|
    +---------------------------------------+

*/

const RegisterButton = ({ text }) => {
    /* A simple Button that redirects the user to the sign up form */
    return (
        <>
            <NavLink className='btn btn-primary' role='button' to='/register'>{text}</NavLink>
        </>
    )
}

const LoginButton = ({ text }) => {
    return (
        <>
            <NavLink className='btn btn-primary' role='button' to='/login'>{text}</NavLink>
        </>
    )
}

export function AccountMenu() {
    const { auth } = useAuth()
    const avatar = useAvatar()
    const logout = useLogout()
    const { t } = useTranslation()


    return (
        <>
            {auth?.accessToken ? (
                <>
                    <div className='d-flex align-items-center' style={{ color: '#fff' }}>
                        <MDBDropdown>
                            <MDBDropdownToggle tag='a' className='nav-link text-reset me-3 hidden-arrow m-0 p-0' role='icon'>
                                <MDBIcon icon='compass'></MDBIcon>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu className='p-2 pt-3 text-center ' style={{ minWidth: '30rem' }}>
                                <h6 className='pt-2 text-uppercase text-muted'>
                                    {t('general.app-menu.title')}
                                </h6>
                                <hr />
                                <br />
                                <MDBRow>
                                    <MDBCol>
                                        <h6>Data</h6>
                                        <MDBDropdownItem link >
                                            <MDBIcon icon='sticky-note' />
                                            <p>{t('general.app-menu.notes')}</p>
                                        </MDBDropdownItem>
                                        <MDBDropdownItem link >
                                            <MDBIcon icon='archive' />
                                            <p>{t('general.app-menu.files')}</p>
                                        </MDBDropdownItem>
                                        <MDBDropdownItem link >
                                            <MDBIcon icon='photo-video' />
                                            <p>{t('general.app-menu.photos')}</p>
                                        </MDBDropdownItem>
                                    </MDBCol>
                                    <MDBCol>
                                        <h6>Org</h6>
                                        <MDBDropdownItem link >
                                            <MDBIcon icon='calendar' />
                                            <p>{t('general.app-menu.planner')}</p>

                                        </MDBDropdownItem>
                                        <MDBDropdownItem link >
                                            <MDBIcon icon='users' />
                                            <p>{t('general.app-menu.lists')}</p>

                                        </MDBDropdownItem>
                                        <MDBDropdownItem link >
                                            <MDBIcon icon='project-diagram' />
                                            <p>{t('general.app-menu.boards')}</p>
                                        </MDBDropdownItem>

                                    </MDBCol>
                                    <MDBCol>
                                        <h6>Com</h6>
                                        <MDBDropdownItem link >
                                            <MDBIcon icon='envelope' />
                                            <p>{t('general.app-menu.mail')}</p>

                                        </MDBDropdownItem>
                                        <MDBDropdownItem link >
                                            <MDBIcon icon='comments' />
                                            <p>{t('general.app-menu.mumbles')}</p>

                                            </MDBDropdownItem>
                                        <MDBDropdownItem link >
                                            <MDBIcon icon='poll-h' />
                                            <p>{t('general.app-menu.polls')}</p>

                                             </MDBDropdownItem>
                                    </MDBCol>
                                    {/**
                                    <MDBCol>
                                        <h6>Code</h6>
                                        <MDBDropdownItem link >
                                            <MDBIcon icon='envelope' /><p>Mail</p> </MDBDropdownItem>
                                        <MDBDropdownItem link >
                                            <MDBIcon icon='comments' /><p>Mumbles</p> </MDBDropdownItem>
                                        <MDBDropdownItem link >
                                            <MDBIcon icon='poll-h' /><p>Polls</p> </MDBDropdownItem>
                                    </MDBCol>
                                     */}
                                </MDBRow>
                                <hr />
                                <a className='p-3'>
                                    {t('general.app-menu.privacy-link')}                                    
                                </a>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                        <MDBDropdown>
                            <MDBDropdownToggle tag='a' className='nav-link text-reset me-3 hidden-arrow m-0 p-0' role='icon'>
                                <MDBIcon icon='bell'></MDBIcon>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                                <MDBDropdownItem link>notification 1</MDBDropdownItem>
                                <MDBDropdownItem link>notification 2</MDBDropdownItem>
                                <MDBDropdownItem link>notification 3</MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                        <MDBDropdown>
                            <MDBDropdownToggle tag='a' className='nav-link  text-reset me-2  rounded hidden-arrow m-0 p-0' role='icon'>
                                {avatar ? <img src={avatar} style={{ maxWidth: '1.8rem', maxHeight: 'auto' }} />: <MDBIcon fas icon='user-circle' /> }
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                                <li>
                                    <NavLink className='dropdown-item' to='/webhosting/subscriptions/'>{t('general.nav.subscriptions')}</NavLink>
                                </li>
                                <li>
                                    <NavLink className='dropdown-item' to='/profile/'>{t('general.nav.profile')}</NavLink>
                                </li>
                                <MDBDropdownItem link onClick={() => { logout() }}>{t('general.nav.logout')}</MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                    </div>
                </>) : (
                <><div className='d-flex align-items-center gap-1'>
                    <RegisterButton text={t('general.btn.register')} />
                    <LoginButton text={t('general.btn.login')} />
                </div>
                </>
            )}
        </>
    )
}
