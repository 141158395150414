import { MDBBtn } from "mdb-react-ui-kit"
import { useNavigate, useLocation } from "react-router-dom"
import {TalkingCowHead} from "components/ui"


const NotFound = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/'
    

    
    // onClick Handler sends the user back or to the home page
    const returnOrHome = () => {
        navigate(from, { replace: true})
    }

    return (
        <section className="slide-in" >

            <TalkingCowHead title='Oops. We did not expect you to show up here.' msg='This Path leads nowhere. Click the Button below to see something useful.' >
                <MDBBtn className="col-8 col-md-4" onClick={returnOrHome} > Return to codingcow</MDBBtn>
            </TalkingCowHead>           
          

            <br />
           
        </section>
    )
}

export default NotFound