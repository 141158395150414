import { MDBBtn, MDBIcon, MDBCol, MDBRow } from "mdb-react-ui-kit"
import { useEffect, useState } from "react"
import AddressCardsView from "features/Shop/components/Addresses/Addresses"
import {useApiPrivate} from "features/Authentication"
import { TabWidget, TabWidgetItem } from "components/ui"
import learning from "assets/img/clipart/learning.png"

/* Clipart Imports */
import {PageClipart} from "components/ui"
import protection from "assets/img/clipart/protection.png"
import control from "assets/img/clipart/control.png"
import settings from "assets/img/clipart/profile-settings.png"
import useUserProfile from "hooks/apiHooks/useUserProfile"


const ProfileView = () => {
    const profileData = useUserProfile() 

    useEffect(()=>{
        console.log(profileData)
    },[profileData])

    return (
        <>
            <section >
                <h3 className="text-capitalize"><MDBIcon icon="user-alt" ></MDBIcon> {profileData?.owner?.username || 'User'}'s Profile </h3>
                <br />
                <div className="border rounded-2">
                <TabWidget>
                    <TabWidgetItem tabTitle='Profile' tabIcon='user-circle'>
                        <ProfileDataView  data={profileData}/>
                        <br />
                        <h5>Addresses</h5>
                        <hr className="hr" />
                        <AddressCardsView />                      

                    </TabWidgetItem>
                    <TabWidgetItem  tabTitle='Security' tabIcon='shield-alt'>
                        <AccountSecurityView data={profileData} />
                    </TabWidgetItem>
                    <TabWidgetItem tabTitle='Manage Your Data' tabIcon='table'>
                        <ControlYourDataView />
                    </TabWidgetItem>
                </TabWidget>
                <br />
             
             
                </div>
                <PageClipart src={learning} caption='Learn how we keep your data safe: [HC LINK]' />
                <br />
            </section>

        </>
    )
}

export default ProfileView


export const ControlYourDataView = () => {
    return (
        <>
        <PageClipart src={control} alt='A symbol for control' caption='Donwload your data or delete your account.' />
            <MDBRow className="m-3 mt-5">
                <MDBCol size='12' lg='2'>
                    <h6>Data Export</h6>
                </MDBCol>
                <MDBCol size='12' lg='8'>
                    <p> This export all your data stored in all services. To Download Websites or your Sever Backups
                        go to the admin panel. </p>
                    <p className="text-danger"> Your Websites and Server Files are not included. </p>
                </MDBCol>
                <MDBCol size='12' lg='2'>
                    <MDBBtn color="outline-primary">Export Data</MDBBtn>
                </MDBCol>
            </MDBRow>
            <hr className="hr" />

            <MDBRow className="m-3">
                <MDBCol size='12' lg='2'>
                    <h6>Delete Account</h6>
                </MDBCol>
                <MDBCol size='12' lg='8'>
                    <p> This will delete your account. All your data will be deleted. </p>
                    <p className="text-danger"> You must not have an ongoing subscription. This action cannot be undone.
                    </p>
                </MDBCol>
                <MDBCol size='12' lg='2'>
                    <MDBBtn color="danger">Delete Account</MDBBtn>
                </MDBCol>
            </MDBRow>
        </>
    )
}



export const AccountSecurityView = ({data}) => {
    return (
        <>
            <PageClipart
                src={protection}
                alt='A Symbol for IT Protection'
                caption=' Keep your account save. See our tips here: [link to article]'
            />
            <MDBRow className="m-3 mt-5">
                <MDBCol size='12' lg='2'>
                    <h6>Yubikeys</h6>
                </MDBCol>
                <MDBCol size='12' lg='8'>
                    <p className="note note-info"> Yubikey Support is comming soon. </p>
                </MDBCol>
                <MDBCol size='12' lg='2'>
                    <MDBBtn color="link"><MDBIcon icon="edit" /> Edit</MDBBtn>
                </MDBCol>
            </MDBRow>
            <hr className="hr" />

            <MDBRow className="m-3 mt-5">

                <MDBCol size='12' lg='2'>
                    <h6>Recovery Email</h6>
                </MDBCol>
                <MDBCol size='12' lg='8'>
                    <p > {data.recovery_email || 'No recovery email set!'}</p>
                </MDBCol>
                <MDBCol size='12' lg='2'>
                    <MDBBtn color="link"><MDBIcon icon="edit" /> Edit</MDBBtn>
                </MDBCol>
            </MDBRow>
            <hr className="hr" />

            <MDBRow className="m-3 mt-5">
                <MDBCol size='12' lg='2'>
                    <h6>Recovery Phone</h6>
                </MDBCol>
                <MDBCol size='12' lg='8'>
                    <p>{data.recovery_phone || 'No recovery phone set!'} </p>
                </MDBCol>
                <MDBCol size='12' lg='2'>
                    <MDBBtn color="link"><MDBIcon icon="edit" /> Edit</MDBBtn>
                </MDBCol>
            </MDBRow>
            <hr className="hr" />

            <MDBRow className="m-3 mt-5">
                <MDBCol size='12' lg='2'>
                    <h6>Password</h6>
                </MDBCol>
                <MDBCol size='12' lg='8'>
                    <p> ****** </p>
                </MDBCol>
                <MDBCol size='12' lg='2'>
                    <MDBBtn color="link"><MDBIcon icon="edit" /> Edit</MDBBtn>
                </MDBCol>
            </MDBRow>
            <hr className="hr" />





        </>
    )
}


export const ProfileDataView = ({data}) => {
    return (
        <>
        <PageClipart src={settings} alt="a settings symbol" caption='Control your Profile Settings here.' />
            <MDBRow className="m-3 mt-5">
                <MDBCol size='12' lg='2'>
                    <h6>Username</h6>
                </MDBCol>
                <MDBCol size='12' lg='8'>
                    <p > {data?.owner?.username}</p>
                </MDBCol>
                <MDBCol size='12' lg='2'>
                    
                </MDBCol>
            </MDBRow>
            <hr className="hr" />
            <MDBRow className="m-3 mt-5">
                <MDBCol size='12' lg='2'>
                    <h6>Name</h6>
                </MDBCol>
                <MDBCol size='12' lg='8'>
                    <p > { `${data?.owner?.first_name} ${data?.owner?.last_name}`} </p>
                </MDBCol>
                <MDBCol size='12' lg='2'>
                    <MDBBtn color="link"><MDBIcon icon="edit" /> Edit</MDBBtn>
                </MDBCol>
            </MDBRow>
            <hr className="hr" />
            <MDBRow className="m-3 mt-5">
                <MDBCol size='12' lg='2'>
                    <h6>Contact Email</h6>
                </MDBCol>
                <MDBCol size='12' lg='8'>
                    <p > {data?.owner?.email}</p>
                </MDBCol>
                <MDBCol size='12' lg='2'>
                    <MDBBtn color="link"><MDBIcon icon="edit" /> Edit</MDBBtn>
                </MDBCol>
            </MDBRow>
            <hr className="hr" />
        </>
    )
}