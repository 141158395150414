import { useEffect } from "react"
import useLocalStorage from "hooks/useLocalStorage"
import axios from "api/axios"

export const useBlog = () => {
    /* We may have to swith to session store with that or add ttl like in this article@
        https://www.sohamkamani.com/javascript/localstorage-with-ttl-expiry/
     */
    const [categories, setCategories] = useLocalStorage("categories", [])
    const [articles, setArticles] = useLocalStorage("articles", [])

    useEffect(() => {
        const getCategories = async () => {
            try {
                const response = await axios.get('/blog/categories/')              
                setCategories(response?.data?.results)
            } catch (err) {
                console.error(err)
            }
        }        
        getCategories()
    }, [])

    useEffect(() => {
        const getArticles = async () => {
            try {
                const response = await axios.get('/blog/articles/')
                setArticles(response?.data?.results)
            } catch (err) {
                console.error(err)
            }
        }
        getArticles()
    }, [])

    const getArticlesByCategory = (categoryName) => {        
        const _filtered =  articles.filter(art => art.category === categoryName )     
        return _filtered
    }

    return(
        [categories, articles, getArticlesByCategory]
    )

}

