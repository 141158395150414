import { useEffect, useState } from "react"
import useUserProfile from "./useUserProfile"

const useAvatar = () => {
    const [avatar, setAvatar] = useState('')
    const profile = useUserProfile()

    useEffect(() => {
        setAvatar(profile.avatar_url);
    },
    [profile]
    )

    return (
        avatar
    )

}

export default useAvatar