import { useEffect, useState } from "react"
import useCsrf from "hooks/useCsrf" 
import useCookies from "hooks/useCookies"

// we abuse the Csrf Token as state secret to verify the request

export const SocialLoginButton = ({ className, provider, client_id }) => {
    const [socialLink, setSocialLink] = useState('#')
    const [csrf] = useCsrf()
    const {setCookie} = useCookies()

    useEffect(() => {        
        let randomString = csrf.slice(2, 10)  // using a defined part of the csrf token as state secret will enable us to verify it with csrf
        const redirect = `http://localhost:3000/social/${provider}/callback/` // Change URL accordingly 
        setCookie( `state${provider}`, randomString, 10 ) // store the state in a cookie as we will lose the App State due to redirects
        
        switch (provider) {
            case 'github':
                setSocialLink(
                    `https://github.com/login/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect}&scope=user&state=${randomString}`
                )
                break;
            case 'figma':
                setSocialLink(
                    `https://www.figma.com/oauth?client_id=${client_id}&redirect_uri=${redirect}&scope=file_read&state=${randomString}&response_type=code`
                )
                break;       
            default:
                /* There should not be a default case in anyway */
                break;
        }
    }, [csrf])

    return (
        <a className={className} href={socialLink} role="button">
            <i className={`fab fa-${provider}`}></i>
        </a>
    )
}