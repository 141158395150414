import { useTranslation } from "react-i18next";
import { TalkingCowHead } from "components/ui";

import { MDBBtn, MDBContainer, MDBInput } from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';

/* The public API without credentials*/
import axios from 'api/axios';

/* Hooks */
import { useAuth } from "features/Authentication";

/* Router */
import { useNavigate } from 'react-router-dom';

export const PasswordResetForm = () => {
    const { t } = useTranslation()
    const { auth } = useAuth()
    const navigate = useNavigate()
    const [user, setUser] = useState('')
    const [email, setEmail] = useState('')
    const [cowMsg, setCowMsg] = useState(t('authentication.description.account-recovery'))

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!email || !user) {
            return
        }
        try {
            const respones = await axios.post('auth/password/reset/', JSON.stringify({
                "email": email        // this is currently not working but seems to be an backend issue       
            }))
            setCowMsg(t('authentication.success.account-recovery'))
        } catch (err) {
            // The only error we ll show is a connection error if one occurs
            // so we will ignore all positive responses and only process            
            // errors if it is a connection error            
            setCowMsg(t('authentication.error.connection'))
        }
    }

    return (
        <>
            {
                auth.accessToken &&
                navigate('/')
            }
            <section className='overflow-hidden'>
                <MDBContainer className='slide-in gap-3 col-12 col-xl-6 border border-0 rounded-5 d-flex flex-column align-items-center' >
                    <TalkingCowHead
                        title={t('authentication.headings.account-recovery')}
                        msg={cowMsg}   // "Sign in to start today's adventure"                 />
                    />
                    <form
                        className='row gap-3 m-2 col-8 justify-content-center'
                        onSubmit={handleSubmit} >
                        {/* onSubmit={handleSubmit} */}
                        <MDBInput name='username'
                            label={t('authentication.register.field.label.username')}
                            inputMode='text'
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                            id='id_username'
                            type='text'
                            required
                        />
                        <MDBInput
                            name='email'
                            label={t('authentication.register.field.label.email')}
                            type='email'
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            id='id_email'
                            required
                        />
                        <MDBBtn
                            className='btn-primary col-6'
                            type='submit'>
                            {t('authentication.btn.submit')}
                        </MDBBtn>
                        <hr className='hr hr-blurry' />
                    </form>
                </MDBContainer>
            </section>
        </>
    )





}