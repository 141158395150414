import { MDBInput, MDBInputGroup } from "mdb-react-ui-kit";
import React from "react";
import Hero from "components/ui/Hero/Hero";
import cloud from "assets/img/img_20190908_124743.jpg"

const Domains = () => {
    return (
        <div className='fade-in'>
            <Hero img={cloud} title='Get the right Domain for your Project' caption='Get your 1st Domain for free with your Webhosting Subscription¹.' />
            <section className="border round p-3">
                <h3>Get your favourite Domain name now</h3>
                <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
            </section>
            <br />
            <section>
                <form>
                    <MDBInputGroup >
                        <MDBInput label='Check Domain Name' placeholder="example.com"/>
                        <MDBInput type='submit' value='Check availability' className="btn-primary"
                        />
                    </MDBInputGroup>
                </form>
            </section>
            <hr className="hr " />
            <section className="small">
                <p className="small text-uppercase text-muted p-3">
                    ¹Not available with our 1$ Wordpress. See each plans details to learn more about different options.
                    <br />
                    <br />
                    All offers are subject to change. Terms and conditions apply.
                </p>
            </section>

        </div>

    )
}

export default Domains